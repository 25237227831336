import { Button, EmptyState, Layout, Link, List, Page, ProgressBar, TextField, Thumbnail, Tooltip } from '@shopify/polaris';
import { useGetVoucher } from 'queries/voucher.query';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import dateandtime from 'date-and-time';
import __helpers from 'helpers/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNotification } from 'NotificationContext';
import voucher_default_image from 'media/images/voucher_default_image.png';
import { EditIcon } from '@shopify/polaris-icons';
export default function ViewVoucher() {
  let history = useNavigate();
  const {
    addNotification
  } = useNotification();
  let {
    voucher_id
  } = useParams();
  const {
    data: entity,
    isLoading,
    isSuccess,
    isError
  } = useGetVoucher(voucher_id);
  const ActualContent = () => <>
      <Helmet>
        <title>{`Thông tin chi tiết: ${entity?.voucher_name}`}</title>
      </Helmet>
      <Page backAction={{
      content: 'back',
      onAction: () => history('/voucher')
    }} title={entity?.voucher_name} primaryAction={{
      content: 'Chỉnh sửa',
      icon: EditIcon,
      onAction: () => history(`/voucher/edit/${voucher_id}`)
    }}>
        <Layout>
          <Layout.Section>
            <div className="voucher">
              <div className="voucher-date">
                <Link url={`/voucher/view/${voucher_id}`}>
                  Hạn đến {dateandtime.format(new Date(Number(entity?.voucher_valid_to)), 'HH:mm DD-MM-YYYY')}
                </Link>
              </div>
              <div className="voucher-content">
                <div className="voucher-value">{entity?.voucher_name}</div>
                <div className="voucher-icon">
                  {entity?.voucher_thumbnail_to_media ? <Thumbnail source={__helpers.getMediaLink(entity?.voucher_thumbnail_to_media.media_thumbnail ? entity?.voucher_thumbnail_to_media.media_thumbnail['128x128'] : entity?.voucher_thumbnail_to_media.media_url)} alt={''} /> : <img src={voucher_default_image} alt="Gift Icon" />}
                </div>
              </div>
              <div style={{
              width: 225
            }}>
                <Tooltip content={`Đã dùng ${entity?.voucher_count} trong tổng số tối đa ${entity?.voucher_count_max}`}>
                  <ProgressBar progress={Math.floor(entity?.voucher_count / entity?.voucher_count_max * 100)} size="small" />
                </Tooltip>
              </div>
              <p className="voucher-terms">{entity?.voucher_excerpt}</p>
              <p className="voucher-terms">
                <span>{entity?.voucher_code}</span>
                &nbsp; &nbsp;
                <CopyToClipboard text={entity?.voucher_code} onCopy={A => addNotification('info', 'Mã đã được copy vào bộ nhớ tạm')}>
                  <Button variant="plain">Copy</Button>
                </CopyToClipboard>
              </p>
            </div>

            <br />
            <br />
            <div>{Parser(entity?.voucher_description ?? '')}</div>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <List type="bullet">
              <List.Item>Cho phép sử dụng?: {entity?.is_active ? 'Có' : 'Không'} </List.Item>
              <List.Item>Loại voucher: {entity?.voucher_category} </List.Item>
              <List.Item>Áp dụng đa tầng?: {entity?.is_multiple === 1 ? 'CÓ' : 'KHÔNG'} </List.Item>
              <List.Item>Tính từ: {dateandtime.format(new Date(Number(entity?.voucher_valid_from)), 'HH:mm DD-MM-YYYY')} </List.Item>
              <List.Item>Dùng đến: {dateandtime.format(new Date(Number(entity?.voucher_valid_to)), 'HH:mm DD-MM-YYYY')} </List.Item>
              <List.Item>Lượt sử dụng tối đa: {__helpers.formatNumberWithCommas(entity?.voucher_count_max)}</List.Item>
              <List.Item>Lượt sử dụng tối đa/ 1 người mua: {__helpers.formatNumberWithCommas(entity?.voucher_count_max_per_user)}</List.Item>
              <List.Item>Đã dùng: {__helpers.formatNumberWithCommas(entity?.voucher_count)}</List.Item>
            </List>
          </Layout.Section>
        </Layout>
      </Page>
    </>;
  return isError ? <EmptyState heading="Ô! Có gì đó không đúng!" action={{
    content: 'Quay lại danh sách',
    onAction: () => history('/voucher')
  }} image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
      <p>Track and receive your incoming inventory from suppliers.</p>
    </EmptyState> : <ActualContent />;
}