const USER_CAPACITY_LIST = ['visitor', 'customer', 'collaborator', 'agency', 'user', 'sale', 'sale_admin', 'stock_manager', 'accountant', 'admin', 'super_admin'];

/** Tình trạng thanh toán */
const PAYMENT_STATUS_CONST = ['order_payment_status_unpaid', 'order_payment_status_partialpaid', 'order_payment_status_paid', 'order_payment_status_refunding', 'order_payment_status_refunded'];
const ORDER_PAYMENT_STATUS = {
  '0': 'order_payment_status_unpaid',
  '1': 'order_payment_status_partialpaid',
  '2': 'order_payment_status_paid',
  '3': 'order_payment_status_refunding',
  '4': 'order_payment_status_refunded'
};
const enum ENUM_PAYMENT_TYPE {
  Bank = 'bank',
  Cash = 'cash',
  Cod = 'cod',
}
const PAYMENT_TYPE_CONST = ['bank', 'cash', 'cod'];
const USER_ACTIVITY_LIST = ['TOUCH', 'BANKING', 'BUYGOOD', 'SOCIAL', 'SUBCRIBE', 'WEBSITE', 'CHECKIN', 'READEMAIL', 'EMAILOUT', 'EMAILIN', 'SMSOUT', 'SMSIN', 'CALLOUT', 'CALLIN', 'LOGIN', 'LOGINAPP', 'LOGINWEB', 'CONTACTFORM', 'SHOPPINGCART'];
enum ENUM_USER_ACTIVITY_LIST {
  'TOUCH' = 'mặc định, không mang ý nghĩa hoặc chưa được định danh',
  'REGISTER' = 'Đăng ký',
  'CALLIN' = 'Gọi tới',
  'CALLOUT' = 'Gọi đy',
  'SMSIN' = 'Nhắn tin tới',
  'SMSOUT' = 'Nhắn tin đy',
  'EMAILIN' = 'Gửi mail tới',
  'EMAILOUT' = 'Gửi mail đi',
  'READEMAIL' = 'Đọc email',
  'CHECKIN' = 'Tới thăm',
  'WEBSITE' = 'Ghé web',
  'SUBCRIBE' = 'Theo dõi',
  'SOCIAL' = 'Tương tác mạng xã hội',
  'LOGIN' = 'Đăng nhập',
  'LOGINWEB' = 'Đăng nhập trên website',
  'LOGINAPP' = ' Đăng nhập qua APP',
  'BUYGOOD' = 'Mua hàng',
  'BANKING' = 'Chuyển tiền qua Internet banking',
  'CONTACTFORM' = 'Liên hệ qua contactform',
  'SHOPPINGCART' = 'Thêm vào giỏ hàng',
}
export { USER_CAPACITY_LIST, PAYMENT_STATUS_CONST, ORDER_PAYMENT_STATUS, ENUM_PAYMENT_TYPE, PAYMENT_TYPE_CONST, USER_ACTIVITY_LIST, ENUM_USER_ACTIVITY_LIST };