import { Card, Badge, Text, IndexTable, Link, Page, useIndexResourceState, Toast, Thumbnail } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DeleteIcon, NoteIcon } from '@shopify/polaris-icons';
import helpers from '../../helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'components/pagination';
import SkeletonLoading from 'components/skeletonPageLoading2';
import __ from 'languages/index';
import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import dateandtime from 'date-and-time';
import BannerModalAddAndEdit from './banner.modal';
import { TypedBanner, useBulkUpdateBanners, useGetBanners } from 'queries/banner.query';
import ListBannerTag from './ListBannerTag';
export default function BannerList() {
  const [entities, setEntities] = useState<TypedBanner[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search: searchParam
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(searchParam) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 25,
    banner_status: '',
    banner_group: '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery
  });
  const {
    data,
    refetch,
    isLoading: loading
  } = useGetBanners(mainQuery);
  useEffect(() => {
    if (!data) return;
    let {
      body,
      totalItems
    } = data;
    setEntities(body);
    setTotalItems(totalItems);
  }, [data]);
  const {
    mutate: updateEntities,
    isPending: updating
  } = useBulkUpdateBanners();

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      // m = Object.assign({}, m, _value);
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const changeTagCallback = useCallback((_value: string) => {
    setMainQuery(oldValue => {
      return {
        ...oldValue,
        ...{
          banner_group: _value
        }
      };
    });
  }, []);

  /**
   * Lòng và lòng vòng, cuối cùng là để nó không gọi quá nhiều lần ...
   */
  const reduceLoad = useCallback((_mainQuery: any) => {
    let buildURLSearch = helpers.buildEndUrl(_mainQuery);
    if (searchParam !== buildURLSearch) window.history.replaceState(null, 'Banner', '/banner' + buildURLSearch);
    refetch();
  }, []);
  const reducerLoading = useMemo(() => helpers.debounce(_value => reduceLoad?.call(this, _value), 900), []);
  useEffect(() => {
    reducerLoading(mainQuery);
  }, [mainQuery]);
  const resourceName = {
    singular: 'banner',
    plural: 'banner'
  };
  const resourceIDResolver = products => {
    return products.banner_id;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(entities ?? [], {
    resourceIDResolver
  });
  const bulkActions = useCallback((_mode: string) => {
    updateEntities({
      banner_id: selectedResources.join(','),
      banner_status: _mode
    });
  }, [selectedResources]);
  const promotedBulkActions = [{
    content: 'Ngừng hiển thị',
    onAction: () => bulkActions('0'),
    icon: DeleteIcon
  }, {
    content: 'Hiển thị',
    onAction: () => bulkActions('1')
  }];
  function badgeStatus(_status) {
    switch (_status) {
      case 0:
        return <Badge>Ngừng hiển thị</Badge>;
      case 1:
        return <Badge tone="success">Hiển thị</Badge>;
      default:
        return <Badge>Chưa rõ</Badge>;
    }
  }
  const rowMarkup = entities?.map((entity, index) => {
    let {
      banner_id,
      banner_title,
      banner_description,
      banner_group,
      media,
      banner_status,
      banner_meta,
      banner_size,
      createdAt
    } = entity;
    return <IndexTable.Row id={banner_id} key={banner_id} selected={selectedResources.includes(banner_id)} position={index}>
        <IndexTable.Cell>
          <Thumbnail source={helpers.getMediaLink(media.media_url, 'https://placehold.co/100x100')} alt={'Banner thumbnail: ' + banner_title} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Link removeUnderline onClick={() => clickToShowEditModal(entity)}>
            {banner_title}
          </Link>
          <Text as="p" tone="subdued" variant="bodySm">
            {banner_description ? helpers.trimContentString(banner_description, 60) : ''}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{badgeStatus(banner_status)}</IndexTable.Cell>
        <IndexTable.Cell>{banner_group}</IndexTable.Cell>
        <IndexTable.Cell>{banner_size}</IndexTable.Cell>
        <IndexTable.Cell>{helpers.trimContentString(JSON.stringify(banner_meta))}</IndexTable.Cell>
        <IndexTable.Cell>{dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY')}</IndexTable.Cell>
      </IndexTable.Row>;
  });
  const [showModal, setShowModal] = useState(false);
  const current_banner_id = useRef(null);
  const clickToShowEditModal = useCallback((bannerData: TypedBanner) => {
    current_banner_id.current = bannerData.banner_id;
    setShowModal(true);
  }, []);
  return <>
      <Helmet>
        <title>Danh sách Banner</title>
      </Helmet>

      <BannerModalAddAndEdit show={showModal} onClose={(el: any) => {
      current_banner_id.current = null;
      setShowModal(false);
    }} current_banner_id={current_banner_id.current} />

      {entities === null ? <SkeletonLoading fullWidth /> : <Page fullWidth title={'Danh sách banner'} primaryAction={{
      content: 'Thêm Banner',
      onAction: () => setShowModal(true)
    }}>
          <ListBannerTag onChange={(id: string) => changeTagCallback(id)} />
          <br />

          <Card padding="0">
            <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
          label: 'Ngừng hiển thị',
          value: '0',
          field: 'banner_status'
        }, {
          label: 'Đang hiển thị',
          value: '1',
          field: 'banner_status'
        }]} sortField={[{
          label: 'Ngày tạo',
          field: 'createdAt'
        }]} />

            <IndexTable resourceName={resourceName} itemCount={entities?.length || 0} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} bulkActions={[]} onSort={null} loading={updating} promotedBulkActions={promotedBulkActions} sortable={[]} headings={[{
          title: ''
        }, {
          title: 'Tên'
        }, {
          title: 'Tình trạng'
        }, {
          title: 'Nhóm'
        }, {
          title: 'Kích thước'
        }, {
          title: 'Đích'
        }, {
          title: 'Ngày tạo'
        }]}>
              {rowMarkup}
            </IndexTable>
          </Card>

          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery.limit)} activeCurrentPage={Number(mainQuery.page)} /> : null}
        </Page>}
    </>;
}