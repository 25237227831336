import { Page, LegacyCard, DataTable, Link, TableData, Text } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dateandtime from 'date-and-time';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import { Helmet } from 'react-helmet-async';
import { ___ } from 'languages/index';
import { useGetReferrers } from 'queries/user_referrer.query';
import SimpleFilter from 'components/simpleFilter';
export default function AffiliateList() {
  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data: EntitiesData
  } = useGetReferrers(mainQuery); // lấy danh sách tất tần tật

  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntites] = useState(null);
  useEffect(() => {
    if (EntitiesData) {
      let {
        body,
        totalItems
      } = EntitiesData;
      setEntites(body);
      setTotalItems(totalItems);
    }
  }, [EntitiesData]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Affiliate', '/affiliate' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [rows, setRows] = useState<TableData[][]>([]);
  const total_order = useRef(0);
  const total_paid = useRef(0);
  useEffect(() => {
    let r = [];
    let _total_order = 0,
      _total_paid = 0;
    if (entities) {
      for (let x of entities) {
        _total_order += x.customer_data?.customer_to_user?.customer_order_count || 0;
        _total_paid += x.customer_data?.customer_to_user?.customer_moneyspent_count || 0;
        r.push([<Link url={'/affiliate/view/' + x?.referrer_data?.user_id}>
            {x.referrer_data?.display_name || x.referrer_data?.user_email || x.referrer_data?.user_phonenumber}
          </Link>, x.referrer_data?.referrer_count, <Link url={'/users/view/' + x?.customer_data?.user_id}>
            {x.customer_data?.display_name || x.customer_data?.user_email || x.customer_data?.user_phonenumber}
          </Link>, dateandtime.format(new Date(Number(x.customer_data?.createdAt)), 'DD/MM/YYYY HH:mm'), x.customer_data?.customer_to_user?.customer_order_count || 0, helpers.formatNumberWithCommas(x.customer_data?.customer_to_user?.customer_moneyspent_count || 0)]);
      }
    }
    setRows(r);
    total_order.current = _total_order;
    total_paid.current = _total_paid;
  }, [entities]);
  return <>
      <Helmet>
        <title>Quản lý Affiliate</title>
      </Helmet>
      <Page title="Affiliate" subtitle="Quản lý danh sách affiliate" compactTitle>
        <LegacyCard>
          <SimpleFilter onCallback={filterCallback} options={[]} loading={false} sortField={[{
          label: 'Số giới thiệu',
          field: 'referrer_data.referrer_count'
        }]} />
          <DataTable columnContentTypes={['text', 'numeric', 'text', 'numeric', 'numeric', 'numeric']} headings={['Người giới thiệu', 'Tổng', 'Khách hàng', 'Ngày đăng ký', 'Đơn hàng', 'Số tiền tiêu']} rows={rows} showTotalsInFooter totals={['', '', '', '', helpers.formatNumber(total_order.current), helpers.formatNumber(total_paid.current, ',')]} hasZebraStripingOnData increasedTableDensity footerContent={___('Show {display_record_number} of {total_record_number} results', {
          display_record_number: <Text as="strong">{rows?.length || 0}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
        </LegacyCard>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}