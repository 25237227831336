import { DataTable, EmptyState, Layout, Page, Card, Text, Thumbnail, Link } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dateandtime from 'date-and-time';
import { LockIcon } from '@shopify/polaris-icons';
import emptyIMG from '../../media/images/empty.png';
import Pagination from '../../components/pagination';
import helpers from '../../helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import SimpleFilter from 'components/simpleFilter';
import { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import PostCategoryFilter from './PostCategoryFilter';
import { TypedPosts, useGetPosts, useGetPostType } from 'queries/posts.query';
export default function PageList() {
  const {
    data: allPostType
  } = useGetPostType();

  // const entity = useAppSelector(state => state.posts.entity);

  const [entities, setEntities] = useState<TypedPosts[]>(null);
  const [totalItems, setTotalItems] = useState<number>(0);

  // loading first time only ...
  const [postType, setPostType] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    let alltypes = [];
    if (allPostType) for (let a of allPostType) {
      alltypes.push({
        label: a,
        value: a,
        field: 'post_type'
      });
    }
    setPostType(alltypes);
  }, [allPostType]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    post_type: '',
    'post_to_category.category_id': '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data: allPosts,
    isLoading: loading,
    refetch: getEntities
  } = useGetPosts(mainQuery);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /** Change cagegory */

  const onChangeCategory = useCallback(category_id => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      'post_to_category.category_id': category_id,
      page: 1
    }));
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/posts' + buildURLSearch);
    getEntities();
  }, [mainQuery]);
  useEffect(() => {
    if (!allPosts) return;
    let {
      body,
      totalItems
    } = allPosts;
    setEntities(body);
    setTotalItems(totalItems);
  }, [allPosts]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  // let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      // m = Object.assign({}, m, _value);
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const emptyData = <EmptyState heading="No post here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  const renderItem = (posts: TypedPosts) => {
    const {
      post_id,
      post_title,
      post_name,
      post_status,
      comment_status,
      post_type,
      post_thumbnail_to_media,
      comment_count,
      createdAt,
      updatedAt
    } = posts;
    return [post_id, <Thumbnail alt={''} source={helpers.getMediaLink(post_thumbnail_to_media?.media_thumbnail ? post_thumbnail_to_media?.media_thumbnail['128x128'] : post_thumbnail_to_media?.media_url)} />, <Link onClick={() => history('/posts/edit/' + post_id)}>
        <Text as="span">{post_title ? helpers.trimMiddleString(post_title, 15, 10) : 'Chưa có tiêu đề'}</Text>
      </Link>, <p>
        <span className="small-icon">{post_status === 'publish' ? '' : <LockIcon />}</span> {post_status}
      </p>, post_type, comment_count, dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm'), updatedAt ? dateandtime.format(new Date(Number(updatedAt)), 'DD-MM-YYYY HH:mm') : '-'];
  };
  const PagesList = totalItems > 0 ? <>
        <DataTable verticalAlign={'middle'} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['ID', '', 'post_title', 'post_status', 'post_type', 'comment_count', 'createdAt', 'updatedAt']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} />
      </> : emptyData;
  const Actual_page = <Page fullWidth title="Bài đăng" subtitle="Posts manager" primaryAction={{
    content: 'Create new post',
    disabled: false,
    onAction: () => {
      history('/posts/new');
    }
  }}>
      <PostCategoryFilter onCallback={onChangeCategory} />

      <Layout>
        <Layout.Section>
          <Card padding="0">
            <SimpleFilter loading={loading} onCallback={filterCallback} options={postType} sortField={[]} />

            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
        </Layout.Section>
      </Layout>
    </Page>;
  return <>
      <Helmet>
        <title>Danh sách bài đăng</title>
      </Helmet>
      {entities === null ? <SkeletonLoading fullWidth /> : Actual_page}
    </>;
}