import { Page, BlockStack, Text, Divider, Checkbox, TextField, Card, Form, Popover, Button, Tag, InlineStack } from '@shopify/polaris';
import QuickUploadFile from 'components/oneclick-upload-file';
import { Toast, ContextualSaveBar } from '@shopify/polaris';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useField, useForm } from '@shopify/react-form';
import { useGetSettings, useUpdateSetting } from 'queries/setting.query';
import { useNotification } from 'NotificationContext';
import __ from 'languages/index';
import __helpers from 'helpers/index';
import QuickSearchUser from 'components/quickSearchUser';
import { useMutateGetUsers } from 'queries/users.query';
import { PlusIcon } from '@shopify/polaris-icons';
import { TypedUser } from 'interface/user.model';
export default function OrderSetting() {
  const {
    addNotification
  } = useNotification();
  const {
    data: entities,
    isLoading: loading
  } = useGetSettings({
    fields: `setting_name,setting_value`,
    setting_name: `order_automatic_cancel_time,order_created_notification,order_created_notification_via_email,order_invoice_template,account_received_noti_for_new_order`
  });
  const {
    mutateAsync: updateSetting,
    isSuccess,
    isPending
  } = useUpdateSetting();
  const useFields = {
    order_automatic_cancel_time: useField<string>({
      value: entities?.order_automatic_cancel_time ?? '0',
      validates: [inputVal => {
        if (inputVal && !__helpers.isNumeric(inputVal)) return 'Phải là một số hợp lệ.';
      }]
    }),
    order_created_notification: useField<string>({
      value: entities?.order_created_notification ?? '0',
      validates: []
    }),
    order_created_notification_via_email: useField<string>({
      value: entities?.order_created_notification_via_email ?? '0',
      validates: []
    }),
    order_invoice_template: useField<string>({
      value: entities?.order_invoice_template ?? '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateSetting({
          order_automatic_cancel_time: Number(values.order_automatic_cancel_time),
          order_created_notification: Number(values.order_created_notification),
          order_created_notification_via_email: Number(values.order_created_notification_via_email),
          order_invoice_template: values.order_invoice_template
        });
      } catch (e: any) {
        console.error(`Submit error`, e);
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  useEffect(() => {
    if (isSuccess) addNotification('info', __('update_successfully'));
  }, [isSuccess]);
  useEffect(() => {
    if (uploadError) addNotification('error', __(uploadError));
  }, [uploadError]);

  /**
   * ENd save quick note
   */

  /** AI NHẬN THÔNG BÁO? */
  const {
    mutate: getUserData,
    data
  } = useMutateGetUsers();
  const [userReceivedNotificationData, setUserReceivedNotificationData] = useState<TypedUser[]>([]);
  useEffect(() => {
    if (data) {
      let {
        body
      } = data;
      setUserReceivedNotificationData(body);
    }
  }, [data]);
  const [userAsigned, setUserAsigned] = useState([]);
  useEffect(() => {
    if (entities?.account_received_noti_for_new_order) {
      setUserAsigned(String(entities?.account_received_noti_for_new_order).split(','));
    }
  }, [entities?.account_received_noti_for_new_order]);
  const [showAddReferrer, setShowAddReferrer] = useState(false);
  const addNewReferrer = useCallback(async (user_id: bigint | string) => {
    let new_record = entities.account_received_noti_for_new_order ? entities.account_received_noti_for_new_order + ',' + user_id : user_id;
    await updateSetting({
      account_received_noti_for_new_order: new_record
    });
  }, [entities?.account_received_noti_for_new_order, updateSetting]);
  const removeTag = useCallback(async (user_id: string) => {
    let newData = userAsigned.filter(previousTag => previousTag !== user_id);
    setUserAsigned(newData);
    await updateSetting({
      account_received_noti_for_new_order: newData.join(',')
    });
  }, [userAsigned]);
  useEffect(() => {
    if (!__helpers.isEmpty(userAsigned)) {
      getUserData({
        user_id: userAsigned.join(','),
        limit: 10,
        sort: 'createdAt:desc'
      });
    }
  }, [userAsigned]);
  return <>
      <Page title="Cài đặt cho đơn hàng" narrowWidth>
        <Form onSubmit={submit}>
          <Card>
            <BlockStack gap="400">
              <TextField autoComplete="off" label="Thời gian tự động hủy đơn" helpText={'Nếu đơn hàng không được xử lý và để quá thời gian này, nó sẽ bị hủy bởi hệ thống.'} suffix={<>Đơn vị tính: Giờ</>} {...useFields.order_automatic_cancel_time} />
              <Checkbox label="Nhận thông báo khi đơn hàng mới được tạo." checked={useFields.order_created_notification.value === '1'} onChange={() => useFields.order_created_notification.onChange(useFields.order_created_notification.value === '0' ? '1' : '0')} />

              <Checkbox label="Gửi email thông báo khi đơn hàng được tạo." checked={useFields.order_created_notification_via_email.value === '1'} onChange={() => useFields.order_created_notification_via_email.onChange(useFields.order_created_notification_via_email.value === '0' ? '1' : '0')} />

              <Divider />

              <Text as="h3" variant="headingMd">
                Người nhận thông báo đơn hàng mới
              </Text>

              <InlineStack gap={'400'}>
                {entities?.account_received_noti_for_new_order ? userReceivedNotificationData?.map((userData, index) => {
                return <Tag key={`jfdskg_${index}`} onRemove={() => removeTag(userData?.user_id)}>
                          {userData.display_name}
                        </Tag>;
              }) : 'Chưa có ai, cài đặt ngay'}
              </InlineStack>

              <Popover active={showAddReferrer} activator={<Button variant="plain" icon={PlusIcon} onClick={() => setShowAddReferrer(true)}>
                    Thêm mới tài khoản nhận thông báo
                  </Button>} autofocusTarget="first-node" onClose={() => setShowAddReferrer(false)}>
                <Card>
                  <QuickSearchUser title="Admin, Sale Admin và Sale" user_role="admin,super_admin,sale_admin,sale" onClose={(userData: any) => {
                  addNewReferrer(userData.user_id);
                  setShowAddReferrer(false);
                }} />
                </Card>
              </Popover>

              <br />
              <Divider />
              <br />
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Template cho hoá đơn
                </Text>
                <Text as="p" tone="subdued" variant="bodySm">
                  Đây là file mẫu để in hóa đơn hoặc gửi hóa đơn qua email cho khách hàng.
                </Text>
                <br />
                <Text as="p" tone="critical">
                  {useFields.order_invoice_template.value || 'Chưa có'}
                </Text>
                <br />
                <QuickUploadFile onSuccess={e => {
                useFields.order_invoice_template.onChange(e.media_filename);
              }} onError={e => setUploadError(e)} children={<Text as="span">Tải lên</Text>} acceptMimeType={['text/html', 'text/htm', 'text/plain']} />
              </BlockStack>
            </BlockStack>
          </Card>
        </Form>
        {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
        onAction: () => submit(),
        loading: isPending
      }} discardAction={{
        onAction: () => resetForm()
      }} />}
      </Page>
    </>;
}