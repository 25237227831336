import { ActionList, Badge, Button, Card, Form, Icon, IndexTable, InlineStack, Link, Page, Popover, Text, TextField, Tooltip, useBreakpoints, useIndexResourceState } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import orderDeliverType from './order.delivery.json';
import orderStatusJson from 'config/order.status.json';
import __, { ___ } from 'languages';
import NoteComponent from '../../components/noteComponent';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import FilterAdvance from './filter';
import { ListBulletedIcon, CheckSmallIcon, AlertDiamondIcon, LockIcon, PlusIcon, OrderFulfilledIcon, DeleteIcon, MoneyFilledIcon, OrderUnfulfilledIcon, RefreshIcon, PageDownIcon } from '@shopify/polaris-icons';
import Pagination from 'components/pagination';
import { TypedOrder, useBulkDownloadOrder, useBulkUpdateOrder, useCreateOrder, useGetOrders, useUpdateOrder, useUpdateOrderStatus } from 'queries/orders.query';
import { useGetLogistics } from 'queries/logistic_service.query';
import { useUpdateorder_fulfillment } from 'queries/order_fulfillment.query';
import { PAYMENT_STATUS_CONST } from 'constant/index';
import { Helmet } from 'react-helmet-async';
import { useNotification } from 'NotificationContext';
import { getBadgeOfPaymentMethod, getOrderDeliveryMethod, getOrderStatusBadge } from './order.component';
import triggerDownload from 'components/triggerDownload';
export default function OrderList() {
  const {
    mutateAsync: updateOrderFulfillment,
    isSuccess: updateFulfillmentSuccess
  } = useUpdateorder_fulfillment();
  const {
    mutateAsync: updateOrder,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateOrder();
  const {
    addNotification
  } = useNotification();
  useEffect(() => {
    if (!updateFulfillmentSuccess) return;
    addNotification('info', __('update_successfully'));
  }, [updateFulfillmentSuccess]);
  useEffect(() => {
    if (!updateSuccess) return;
    addNotification('info', __('update_successfully'));
  }, [updateSuccess]);
  const orderStatusArray = [];
  for (let orderStatus in orderStatusJson) {
    let orderStatusName = orderStatusJson[orderStatus];
    orderStatusArray.push({
      label: orderStatusName,
      value: orderStatus
    });
  }

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    order_status: null,
    payment_status: null,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
    // ...stateProp
  });
  const {
    data: rootData,
    isLoading: loading,
    refetch: getEntities
  } = useGetOrders(mainQuery);
  const {
    mutateAsync: createEntity,
    data: newCreateData
  } = useCreateOrder();
  const history = useNavigate();
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (rootData) {
      let {
        body,
        totalItems
      } = rootData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [rootData]);

  /**
   * create new Order
   */

  const createNewEmptyOrder = useCallback(() => {
    createEntity({}).then().catch(e => {});
  }, []);
  useEffect(() => {
    if (newCreateData) {
      history('/order/view/' + newCreateData.order_id);
    }
  }, [newCreateData]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Order', '/order' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * THay đổi loại giao vận
   */
  const [showDeliveryType, setShowDeliveryType] = useState(null);
  const onClosePophoverOderCallback = useCallback((order_id: any) => {
    setShowDeliveryType(!order_id);
  }, []);
  const togglePophoverOrderCallback = useCallback((_s: any) => {
    setShowDeliveryType(showDeliveryType === _s ? false : _s);
  }, [showDeliveryType]);
  const savingDeliveryTypeCallback = useCallback(async (order_id: any, order_delivery_type: any) => {
    try {
      await updateOrder({
        order_id: order_id,
        order_delivery_type: order_delivery_type
      });
    } catch (e) {}
    onClosePophoverOderCallback(order_id);
  }, []);

  /**
   * Thay đổi đơn vị vận chuyển
   * @param media
   * @returns
   */

  const [showLogisticService, setShowLogisticService] = useState(null);
  const onCloseLogisticServiceCallback = useCallback((order_id: any) => {
    setShowLogisticService(!order_id);
  }, []);
  const toggleLogisticServiceCallback = useCallback((_s: any) => {
    setShowLogisticService(showLogisticService === _s ? false : _s);
  }, [showLogisticService]);
  const savingLogisticServiceCallback = useCallback((order_id: any, logistic_id: any) => {
    updateOrderFulfillment({
      order_id: order_id,
      logistic_id: logistic_id
    });
    onCloseLogisticServiceCallback(order_id);
  }, []);

  /**!SECTION
   * Get all logistic service and memo it
   */

  const {
    data: logisticEntities
  } = useGetLogistics({
    sort: 'createdAt:desc',
    limit: 100,
    logistic_status: 1
  });
  const findLogistic = useCallback((keyword: string, order_id: any, current_logistic_id: any): any[] => {
    let matchLogistic = [];
    if (logisticEntities && typeof logisticEntities.body !== 'undefined') for (let logistic of logisticEntities?.body) {
      if (logistic.logistic_delivery_method.includes(keyword)) matchLogistic.push({
        content: logistic.logistic_name,
        onAction: () => savingLogisticServiceCallback(order_id, logistic.logistic_id),
        suffix: logistic.logistic_id === current_logistic_id && <Icon source={CheckSmallIcon} />,
        active: logistic.logistic_id === current_logistic_id
      });
    }
    return matchLogistic;
  }, [logisticEntities, savingLogisticServiceCallback]);

  /**
   * Thay đổi mã vận chuyển
   */

  const [currentFulfillmentCode, setCurrentFulfillmentCode] = useState('');
  const [showLogisticFulfillmentCode, setShowLogisticFulfillmentCode] = useState(null);
  const onCloseFulfillmentCallback = useCallback((order_id: any) => {
    setShowLogisticFulfillmentCode(!order_id);
  }, []);
  const toggleFulfillmentCallback = useCallback((_s: any, current_value: string) => {
    setShowLogisticFulfillmentCode(showLogisticFulfillmentCode === _s ? false : _s);
    setCurrentFulfillmentCode(current_value);
  }, [showLogisticFulfillmentCode]);
  const savingFulfillmentCallback = useCallback(async () => {
    updateOrderFulfillment({
      order_id: showLogisticFulfillmentCode,
      order_fulfillment_code: currentFulfillmentCode
    });
    onCloseFulfillmentCallback(showLogisticFulfillmentCode);
  }, [showLogisticFulfillmentCode, currentFulfillmentCode]);

  /** Tình trạng thanh toán */

  const [showPaymentStatusPopup, setShowPaymentStatusPopup] = useState(null);
  const toggleShowPaymentStatusPopup = useCallback((_s: any) => {
    setShowPaymentStatusPopup(showPaymentStatusPopup === _s ? false : _s);
  }, [showPaymentStatusPopup]);
  const changePaymentStatusCallback = useCallback(async (order_id: string, new_status: number) => {
    try {
      await updateOrder({
        order_id: order_id,
        payment_status: Number(new_status)
      });
    } catch (e) {
      console.error(e, 'ERR_87687HJ');
    }
    setShowPaymentStatusPopup(null);
  }, []);

  /**
   * Ghi chú đơn hàng ...
   */

  const [noteForOrder, setNoteForOrder] = useState('');
  const [showPophoverOrderNote, setShowPophoverOrderNote] = useState(null);
  const onClosePophoverOderNoteCallback = useCallback((customer_campaign_id: any) => {
    setShowPophoverOrderNote(!customer_campaign_id);
    setNoteForOrder('');
  }, []);
  const togglePophoverOrderNoteCallback = useCallback((_s: any, order_note: string) => {
    setShowPophoverOrderNote(showPophoverOrderNote === _s ? false : _s);
    setNoteForOrder(order_note);
  }, [showPophoverOrderNote]);
  const submitNoteOrderCallback = useCallback(async (value: string, order_id: any) => {
    try {
      await updateOrder({
        order_id: order_id,
        order_note: value
      });
    } catch (e) {}
    setShowPophoverOrderNote(null);
    setNoteForOrder('');
  }, []);

  /**
   * Order status
   */
  const {
    mutateAsync: updateOrderStatus,
    isSuccess: updateOrderStatusSuccess,
    isError: updateOrderStatusError
  } = useUpdateOrderStatus();
  const [showPopupForChangeOrderStatus, setShowPopupForChangeOrderStatus] = useState('');
  const changeOrderStatus = useCallback(async (order_id: any, new_status: number) => {
    try {
      await updateOrderStatus({
        order_id: order_id,
        order_status: Number(new_status)
      });
      setShowPopupForChangeOrderStatus('');
    } catch (e) {}
  }, []);

  /**
   * END order Status
   */

  const renderItem = (orderDetail: TypedOrder, index) => {
    const {
      order_id,
      order_pnr,
      order_status,
      order_note,
      order_total_price,
      order_total_mustpay,
      order_fulfillment,
      order_delivery_type,
      order_checked,
      payment_type,
      payment_status,
      user_id,
      createdAt,
      customer_data
    } = orderDetail;
    let order_fulfillment_error = order_fulfillment?.order_fulfillment_error;
    return <IndexTable.Row disabled={order_checked === 1} id={order_id} key={order_id} selected={selectedResources.includes(order_id)} position={index}>
        <IndexTable.Cell>
          <Button variant="plain" onClick={() => history('/order/view/' + order_id)}>
            {order_pnr}
          </Button>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="small-icon">
            {order_checked === 1 ? <Tooltip content="Đã đối soát">
                <Text as="span">
                  <LockIcon />
                </Text>
              </Tooltip> : ''}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>{createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm') : '-'}</IndexTable.Cell>
        <IndexTable.Cell>
          {order_checked ? getOrderStatusBadge(order_status) : <Popover active={showPopupForChangeOrderStatus === order_id} activator={<>
                  <Link onClick={() => setShowPopupForChangeOrderStatus(order_id)}>{getOrderStatusBadge(order_status)}</Link>
                </>} autofocusTarget="first-node" onClose={() => setShowPopupForChangeOrderStatus('')}>
              <ActionList actionRole="menuitem" items={orderStatusArray.map((el, index) => {
            return {
              content: el.label,
              onAction: () => changeOrderStatus(order_id, el.value),
              suffix: String(order_status) === el.value && <Icon source={CheckSmallIcon} />
            };
          })} />
            </Popover>}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order_checked ? getOrderDeliveryMethod(order_delivery_type) : <Popover active={showDeliveryType === order_id} activator={<Link removeUnderline onClick={() => togglePophoverOrderCallback(order_id)}>
                  {order_delivery_type ? getOrderDeliveryMethod(order_delivery_type) : <>
                      <Badge></Badge>
                      <Badge></Badge>
                    </>}
                </Link>} onClose={() => onClosePophoverOderCallback(order_id)} ariaHaspopup={false}>
              <ActionList actionRole="menuitem" items={Object.entries(orderDeliverType).map(([key, value]) => {
            return {
              content: __(value),
              onAction: () => savingDeliveryTypeCallback(order_id, key),
              suffix: String(order_delivery_type) === key && <Icon source={CheckSmallIcon} />,
              active: String(order_delivery_type) === key
            };
          })} />
            </Popover>}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{
          minWidth: '180px'
        }}>
            <InlineStack gap={'100'} align="start" blockAlign="center">
              {order_fulfillment_error && <span title={order_fulfillment_error}>
                  <span className="Polaris-Text--root Polaris-Text--bodySm" style={{
                fontSize: '12px'
              }}>
                    <Icon source={AlertDiamondIcon} tone="critical" />
                  </span>
                </span>}

              {order_checked ? order_fulfillment?.logistic_service?.logistic_name || <>
                    <Badge></Badge>
                    <Badge></Badge>
                  </> : <Popover active={showLogisticService === order_id} activator={<Link removeUnderline onClick={() => toggleLogisticServiceCallback(order_id)}>
                      {order_fulfillment?.logistic_service ? order_fulfillment?.logistic_service?.logistic_name : <>
                          <Badge></Badge>
                          <Badge></Badge>
                        </>}
                    </Link>} onClose={() => onCloseLogisticServiceCallback(order_id)} ariaHaspopup={false}>
                  <ActionList actionRole="menuitem" sections={[{
                items: findLogistic(orderDeliverType[order_delivery_type], order_id, order_fulfillment?.logistic_service?.logistic_id)
              }]} />
                </Popover>}
            </InlineStack>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order_checked || order_status !== 2 ? order_fulfillment?.order_fulfillment_code || <>
                <Badge></Badge>
                <Badge></Badge>
              </> : <Popover active={showLogisticFulfillmentCode === order_id} activator={<Link removeUnderline onClick={() => toggleFulfillmentCallback(order_id, order_fulfillment?.order_fulfillment_code || '')}>
                  {order_fulfillment?.order_fulfillment_code ? order_fulfillment?.order_fulfillment_code : <>
                      <Badge></Badge>
                      <Badge></Badge>
                    </>}
                </Link>} onClose={() => onCloseFulfillmentCallback(order_id)} ariaHaspopup={false} sectioned>
              <Form onSubmit={savingFulfillmentCallback}>
                <TextField label="Mã vận chuyển" value={currentFulfillmentCode} onChange={val => setCurrentFulfillmentCode(val)} autoComplete="off" />
              </Form>
            </Popover>}
        </IndexTable.Cell>
        <IndexTable.Cell>{helpers.formatNumber(order_total_mustpay, ',')}</IndexTable.Cell>
        <IndexTable.Cell>{payment_type}</IndexTable.Cell>
        <IndexTable.Cell>
          {<Popover active={showPaymentStatusPopup === order_id} activator={<Link onClick={() => toggleShowPaymentStatusPopup(order_id)}>{getBadgeOfPaymentMethod(payment_status)}</Link>} onClose={() => toggleShowPaymentStatusPopup(order_id)} ariaHaspopup={false} sectioned>
              {/** PAYMENT .... */}
              <ActionList actionRole="menuitem" sections={[{
            items: PAYMENT_STATUS_CONST.map((el, index) => {
              return {
                content: __(el),
                onAction: () => changePaymentStatusCallback(order_id, index),
                suffix: payment_status === index && <Icon source={CheckSmallIcon} />
              };
            })
          }]} />
            </Popover>}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="sale_order_note">
            <Popover active={showPophoverOrderNote === order_id} activator={<Link removeUnderline onClick={() => togglePophoverOrderNoteCallback(order_id, order_note)}>
                  {order_note || <>
                      <Badge></Badge>
                      <Badge></Badge>
                    </>}
                </Link>} onClose={() => onClosePophoverOderNoteCallback(order_id)} ariaHaspopup={false} sectioned>
              <NoteComponent label="Ghi chú đơn hàng" default_string={noteForOrder} onSave={submitNoteOrderCallback} args={order_id} />
            </Popover>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {user_id ? <Link onClick={() => history(`/customer/view/${user_id}`)}>
              {customer_data?.display_name || customer_data?.user_email || customer_data?.user_phonenumber || '---'}
            </Link> : <>
              <Badge></Badge>
              <Badge></Badge>
            </>}
        </IndexTable.Cell>
      </IndexTable.Row>;
  };

  /** Bulk update */
  /** Bulk update */
  /** Bulk update */
  /** Bulk update */
  const {
    mutate: bulkUpdateOrder,
    isSuccess: batchUpdateSuccess
  } = useBulkUpdateOrder();
  const {
    mutateAsync: downloadOrders
  } = useBulkDownloadOrder();
  function resourceIDResolver(orders: any) {
    return orders.order_id;
  }
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(entities || [], {
    resourceIDResolver
  });

  /** Dọn / xóa order rác ... */
  const clearDraftOrderCallback = useCallback(() => {
    bulkUpdateOrder({
      mode: 'delete_order_draft',
      mode_value: '',
      orders: selectedResources
    });
  }, [bulkUpdateOrder, selectedResources]);

  /** Hủy đơn hàng loạt ... */
  const cancelOrderCallback = useCallback(() => {
    bulkUpdateOrder({
      mode: 'cancel_order',
      mode_value: '',
      orders: selectedResources
    });
  }, [bulkUpdateOrder, selectedResources]);

  /** Đánh dấu đã trả tiền hàng loạt ... */
  const markOrderRefunCallback = useCallback(() => {
    bulkUpdateOrder({
      mode: 'mark_as_refuned',
      mode_value: '',
      orders: selectedResources
    });
  }, [bulkUpdateOrder, selectedResources]);

  /** Đánh dấu đã trả tiền hàng loạt ... */
  const downloadOrderCallback = useCallback(async () => {
    try {
      let data = await downloadOrders(selectedResources);
      triggerDownload(data, 'orders_export.csv', 'text/csv');
    } catch (e) {}
  }, [selectedResources]);
  useEffect(() => {
    if (batchUpdateSuccess) {
      addNotification('info', __('update_successfully'));
    }
  }, [batchUpdateSuccess]);
  const bulkActions = [{
    title: 'Kế toán',
    items: [{
      content: 'Đã đối soát',
      icon: OrderFulfilledIcon,
      onAction: () => alert('Đang phát triển ...')
    }, {
      icon: MoneyFilledIcon,
      content: 'Đã hoàn tiền',
      onAction: () => markOrderRefunCallback()
    }]
  }, {
    title: 'Khác',
    items: [{
      icon: OrderUnfulfilledIcon,
      destructive: true,
      content: 'Hủy đơn',
      onAction: () => cancelOrderCallback()
    }, {
      icon: DeleteIcon,
      destructive: true,
      content: 'Xóa bỏ đơn nháp',
      onAction: () => clearDraftOrderCallback()
    }, {
      icon: PageDownIcon,
      content: 'Tải các đơn này về',
      onAction: () => downloadOrderCallback()
    }]
  }];
  const OrderListTable = <>
      <IndexTable loading={loading} condensed={useBreakpoints().smDown} resourceName={{
      singular: 'Đơn hàng',
      plural: 'Đơn hàng'
    }} itemCount={entities?.length || 0} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} headings={[{
      title: 'PNR'
    }, {
      title: ''
    }, {
      title: 'Ngày tạo'
    }, {
      title: 'Tình trạng'
    }, {
      title: 'Vận chuyển'
    }, {
      title: 'D.vụ vận chuyển'
    }, {
      title: 'Mã vận chuyển'
    }, {
      title: 'Giá trị'
    }, {
      title: 'Cách thanh toán'
    }, {
      title: 'Thanh toán'
    }, {
      title: 'Ghi chú'
    }, {
      title: 'Khách hàng'
    }]} bulkActions={bulkActions}>
        {entities?.map(renderItem) || []}
      </IndexTable>
    </>;
  return <>
      <Helmet>
        <title>Danh sách đơn hàng</title>
      </Helmet>
      <Page fullWidth title="Danh sách đơn hàng" compactTitle primaryAction={{
      content: 'Thêm đơn hàng',
      loading: updating,
      icon: PlusIcon,
      onAction: createNewEmptyOrder
    }} secondaryActions={[{
      content: 'Tải lại',
      icon: RefreshIcon,
      onAction: () => getEntities()
    }, {
      content: 'Lên đơn nhanh',
      icon: OrderFulfilledIcon,
      disabled: true,
      onAction: () => alert('Đang phát triển')
    }, {
      content: 'Đối soát hàng loạt',
      icon: ListBulletedIcon,
      disabled: true,
      onAction: () => alert('Đang hoàn thiện')
    }]}>
        <Card padding="0">
          <FilterAdvance loading={loading} onCallback={filterCallback} />
          {OrderListTable}
        </Card>
        <br />
        {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={Number(mainQuery?.page)} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
      </Page>
      <br />
      <br />
    </>;
}