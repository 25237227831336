import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedTransaction_to_order {
    "id"?: string,
    "transaction_id"?: string,
    "order_id"?: string,
    "transactions"?: {
        "transaction_id"?: string,
        "sender_wallet_id"?: string,
        "receiver_wallet_id"?: string,
        "amount"?: number,
        "transaction_type"?: string,
        "transaction_category"?: string,
        "transaction_note"?: string,
        "transaction_hash"?: string,
        "createdAt"?: string,
        wallet_sender?: {
            user: {
                display_name: string,
                user_phonenumber: string,
                user_email: string,
                user_login: string,
                user_id: string,
            }
        }
        wallet_receiver?: {
            wallet_type: {
                "wallet_unit": string,
                "wallet_sign": string
            },
            user: {
                display_name: string,
                user_phonenumber: string,
                user_email: string,
                user_login: string,
                user_id: string,
            }
        }
    }

}



type IQuery = TypedTransaction_to_order & IQueryParams;


/*
* List of all app
*/
export function useGetTransaction_to_orders(order_id: string) {
    return useQuery({
        queryKey: ["transaction_to_order/fetch_entity_list"],
        queryFn: () => axios.get<TypedTransaction_to_order[]>(`/transaction_to_order/${order_id}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}