import { Page, Text, DataTable, Link, TableData, LegacyCard, BlockStack } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dateandtime from 'date-and-time';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import { useParams } from 'react-router-dom';
import SkeletonLoading from 'components/skeletonPageLoading';
import { ___ } from 'languages/index';
import { useGetReferrer } from 'queries/user_referrer.query';
import { Helmet } from 'react-helmet-async';
import SimpleFilter from 'components/simpleFilter';
export default function AffiliateView() {
  let {
    affiliate_id
  } = useParams();
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>(initialQuery);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const {
    refetch: getEntity,
    data: Entitydata,
    isLoading: loading
  } = useGetReferrer({
    user_id: affiliate_id,
    ...mainQuery
  }); // lấy danh sách của một ông ...

  const [totalItems, setTotalItems] = useState(0);
  const [entity, setEntity] = useState(null);
  useEffect(() => {
    if (Entitydata) {
      let {
        body,
        totalItems
      } = Entitydata;
      setEntity(body);
      setTotalItems(totalItems);
    }
  }, [Entitydata]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntity();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [rows, setRows] = useState<TableData[][]>([]);
  const total_order = useRef(0);
  const total_paid = useRef(0);
  useEffect(() => {
    let r = [];
    let _total_order = 0,
      _total_paid = 0;
    if (entity) {
      for (let x of entity.referrers) {
        _total_order += x.customer_to_user?.customer_order_count || 0;
        _total_paid += x.customer_to_user?.customer_moneyspent_count || 0;
        r.push([<Link url={'/users/view/' + x?.user_id}>{x.display_name || x.user_email}</Link>, dateandtime.format(new Date(Number(x.createdAt)), 'DD/MM/YYYY'), x.customer_to_user?.customer_order_count || 0, helpers.formatNumberWithCommas(x.customer_to_user?.customer_moneyspent_count || 0)]);
      }
    }
    setRows(r);
    total_order.current = _total_order;
    total_paid.current = _total_paid;
  }, [entity]);
  return <>
      <Helmet>
        <title>{entity?.user?.display_name}</title>
      </Helmet>
      {loading ? <SkeletonLoading /> : <Page backAction={{
      content: 'BACK',
      url: '/affiliate'
    }} title={entity?.user.display_name} subtitle={'Mã giới thiệu: ' + entity?.user?.referrer_code}>
          <BlockStack gap="400">
            <Text as="p">
              <Link url={`/users/view/${entity?.user?.user_id}`}>{entity?.user.display_name}</Link> giới thiệu được{' '}
              {Number(entity?.user?.referrer_count || 0)} người
            </Text>
            <LegacyCard>
              <SimpleFilter onCallback={filterCallback} options={[]} loading={false} sortField={[]} />
              <DataTable columnContentTypes={['text', 'text', 'numeric', 'numeric']} headings={['Được giới thiệu', 'Ngày đăng ký', 'Đơn hàng', 'Số tiền tiêu']} rows={rows} showTotalsInFooter totals={['', '', helpers.formatNumber(total_order.current), helpers.formatNumber(total_paid.current, ',')]} sortable={[false, true, true]} defaultSortDirection="descending" initialSortColumnIndex={4} onSort={null} hasZebraStripingOnData increasedTableDensity footerContent={___('Show {display_record_number} of {total_record_number} results', {
            display_record_number: <Text as="strong">{rows?.length || 0}</Text>,
            total_record_number: <Text as="strong">{totalItems}</Text>
          })} />
            </LegacyCard>

            <br />
            {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
          </BlockStack>
        </Page>}
    </>;
}