import { IndexTable, LegacyCard, useBreakpoints, Text, useIndexResourceState, Banner, Link } from '@shopify/polaris';
import Pagination from 'components/pagination';
import { useAppDispatch, useAppSelector } from 'config/store';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { clearError, deleteEntity, getEntities, reset } from 'store/blacklist_device_uuid.store.reducer';
import { DeleteIcon } from '@shopify/polaris-icons';
import SimpleFilter from 'components/simpleFilter';
import AddDeviceUUIDModal from './addDeviceUUIDModal';
export default function IPBlacklist() {
  const entities = useAppSelector(state => state.blacklist_device_uuid.entities);
  const loading = useAppSelector(state => state.blacklist_device_uuid.loading);
  const totalItems = useAppSelector(state => state.blacklist_device_uuid.totalItems);
  const dispatch = useAppDispatch();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */

  const initialQuery = {
    query: '',
    page: 1,
    limit: 10,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const queryCallback = useCallback(async () => {
    await dispatch(reset());
    await dispatch(getEntities(mainQuery));
  }, [mainQuery]);
  useEffect(() => {
    queryCallback();
  }, [mainQuery]);
  const resourceIDResolver = (bannedData: any) => {
    return bannedData.id ?? null;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection
  } = useIndexResourceState(entities, {
    resourceIDResolver
  });
  const rowMarkup = entities?.map(({
    id,
    device_uuid
  }, index) => <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
      <IndexTable.Cell>
        <Text variant="bodyMd" fontWeight="bold" as="span">
          {device_uuid}
        </Text>
      </IndexTable.Cell>
    </IndexTable.Row>);
  const deleteBannedUUID = useCallback(async () => {
    dispatch(deleteEntity(selectedResources)).unwrap().then(response => {
      queryCallback();
      clearSelection();
    }).catch(e => {});
  }, [dispatch, queryCallback, selectedResources, clearSelection]);
  const promotedBulkActions = [{
    icon: DeleteIcon,
    destructive: true,
    content: 'Delete device_uuid',
    onAction: deleteBannedUUID
  }];
  const [showModal, setShowModal] = useState(false);
  const showAddnewModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  return <>
      <Helmet>
        <title>Danh sách device UUID bị cấm truy cập</title>
      </Helmet>

      <AddDeviceUUIDModal show={showModal} onClose={() => {
      setShowModal(false);
      queryCallback();
    }} />

      <Banner tone="critical">
        <p>
          Tất cả các device UUID trong danh sách này được bạn thêm vào thủ công, hoặc được chức năng bảo vệ của hệ thống THÊM VÀO một cách tự động.
          <br />
          Các truy vấn từ các device UUID này sẽ không thể làm một số việc như đăng nhập hoặc gửi yêu cầu hỗ trợ.
          <br />
          <Link onClick={showAddnewModal}>Thêm một device UUID vào danh sách</Link> nếu bạn nghi ngờ bị tấn công.
        </p>
      </Banner>

      <br />
      <br />

      <LegacyCard>
        <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
        <IndexTable condensed={useBreakpoints().smDown} resourceName={{
        singular: 'UUID',
        plural: 'UUID'
      }} itemCount={entities?.length ?? 0} headings={[{
        title: 'Chọn tất cả UUID'
      }]} promotedBulkActions={promotedBulkActions} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} selectable={true}>
          {rowMarkup}
        </IndexTable>
      </LegacyCard>

      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
      <br />
      <br />
    </>;
}