import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'config/store';
import CustomerCampaignNewModel from '../customer_campaign.modal';
import Hotkeys from 'react-hot-keys';
import { PlusIcon, EditIcon } from '@shopify/polaris-icons';
import { getAnalytics, getEntity } from 'store/customer_campaign.store.reducer';
import { Page, Toast, Loading, Badge, Text, InlineGrid, BlockStack, InlineStack, Divider, Tabs, Card, Avatar } from '@shopify/polaris';
import helpers from 'helpers';
import CampaignAnalytics from './campaign_analytics';
import CampaignCustomerList from './campaign_customer_list';
import dateandtime from 'date-and-time';
import Progressbar from 'components/progressbar';
import CampaignOrderList from './campaign_order_list';
import Comments from 'components/CommentsComponent';
import CustomerBirthDayList from '../../../components/customer_birthday';
import AddNewCustomerForCustomerCampaign from 'components/addNewCustomer.modal';
import UserAssignToCustomerCampaign from './user.assign';
export default function CustomerCampaignView() {
  const loading = useAppSelector(state => state.customer_campaign.loading);
  const updating = useAppSelector(state => state.customer_campaign.updating);
  const customerCampaignSingleData = useAppSelector(state => state.customer_campaign.entity);
  const dispatch = useAppDispatch();

  /**
   * Chỉnh sửa lại thông tin chiến dịch
   */

  const [showModelEdit, setShowModelEdit] = useState(false);
  const toggleModelActive = useCallback(() => setShowModelEdit(active => !active), []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */

  let _useParam = {} as any;
  _useParam = useParams();
  let Param = _useParam.customer_campaign_id || false;
  useEffect(() => {
    dispatch(getEntity(Param));
    dispatch(getAnalytics(Param));
  }, []);
  const a = useAppSelector(state => state.customer_campaign.analytics);
  const {
    best_sale,
    total_profit
  } = a || [];
  const [progressData, setProgressData] = useState([]);
  useEffect(() => {
    if (!best_sale) return;
    let finalProgressbar = [];
    for (let p in best_sale) {
      let vc = best_sale[p];
      finalProgressbar.push({
        name: vc.display_name,
        value: vc.total_order
      });
    }
    setProgressData(finalProgressbar);
  }, [best_sale]);
  let caculatePercent = helpers.caculatePercent(customerCampaignSingleData?.campaign_target, customerCampaignSingleData?.campaign_count);
  let targetNumber = helpers.getPad(customerCampaignSingleData?.campaign_target ?? 0);
  let gotNumber = helpers.getPad(customerCampaignSingleData?.campaign_count ?? 0);
  let gotOrderNumber = helpers.getPad(customerCampaignSingleData?.campaign_order_count ?? 0);
  let deadline = helpers.subtractDate(customerCampaignSingleData?.campaign_deadline);
  let deadlineBadge = Number(deadline) < 0 ? Math.abs(Number(deadline)) : 0;

  /**
   * Add new Customer ...
   */

  const [newCustomerModal, setNewCustomerModal] = useState(false);

  /**
   * TABS
   */
  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setTabselected(selectedTabIndex), []);
  const tabs = [{
    id: 'tab_1',
    content: 'Khách hàng',
    panelID: 'customer'
  }, {
    id: 'tab_2',
    content: 'Đơn hàng',
    panelID: 'orders'
  }, {
    id: 'tab_3',
    content: 'Thảo luận',
    panelID: 'comments'
  }];
  return <>
      {loading || updating ? <Loading /> : null}
      <AddNewCustomerForCustomerCampaign show={newCustomerModal} default_campaign={Param} onClose={() => {
      setNewCustomerModal(false);
    }} />
      <Page fullWidth titleMetadata={customerCampaignSingleData?.campaign_status === 1 ? <Badge tone="success">Đang diễn ra</Badge> : <Badge tone="warning">Đã kết thúc</Badge>} backAction={{
      content: 'Danh sách khách hàng',
      url: '/customer_campaign'
    }} title={customerCampaignSingleData?.campaign_name ?? '-'} secondaryActions={[{
      content: 'Chỉnh sửa',
      disabled: customerCampaignSingleData?.campaign_status === 0,
      icon: EditIcon,
      onAction: toggleModelActive
    }, {
      content: 'Thêm khách',
      icon: PlusIcon,
      onAction: () => setNewCustomerModal(true)
    }]}>
        <InlineStack gap={'400'} align="start" blockAlign="center">
          <InlineStack gap={'200'} blockAlign="center">
            <Avatar size="sm" source={customerCampaignSingleData?.user?.user_avatar} initials={(customerCampaignSingleData?.user?.display_name || 'u').charAt(0)} />
            <BlockStack>
              <Text as="span" fontWeight="bold">
                {customerCampaignSingleData?.user?.display_name || customerCampaignSingleData?.user?.user_login}
              </Text>
              <Text as="span" variant="bodySm" tone="subdued">
                {`Tạo ` + String(customerCampaignSingleData ? helpers.subtractDate(customerCampaignSingleData?.createdAt) : '1') + ' ngày trước'}
              </Text>
            </BlockStack>
          </InlineStack>
        </InlineStack>

        <br />

        <InlineGrid columns={{
        xs: '1',
        sm: '1',
        md: '1',
        lg: ['oneThird', 'twoThirds']
      }} gap="400">
          <div>
            <BlockStack gap={'200'} align="start" inlineAlign="start">
              <InlineStack gap={'200'} blockAlign="center" align="space-between">
                <Text as="span">Bắt đầu</Text>
                <Text as="span" tone="subdued" fontWeight="bold">
                  {dateandtime.format(new Date(Number(customerCampaignSingleData?.createdAt)), 'DD/MM/YYYY')}
                </Text>
              </InlineStack>

              <InlineStack gap={'200'} blockAlign="center" align="space-between">
                <Text as="span">Kết thúc</Text>
                <Text as="span" tone="subdued" fontWeight="bold">
                  {dateandtime.format(new Date(Number(customerCampaignSingleData?.campaign_deadline)), 'DD/MM/YYYY')}, còn {deadlineBadge} ngày
                </Text>
              </InlineStack>

              <InlineStack gap={'200'} blockAlign="center" align="space-between">
                <Text as="span">Sản phẩm</Text>
                <Text as="span" tone="subdued" fontWeight="bold">
                  {String(customerCampaignSingleData?.product?.product_name || '-')}
                </Text>
              </InlineStack>

              <InlineStack gap={'200'} blockAlign="center" align="space-between">
                <Text as="span">Chỉ tiêu</Text>
                <Text as="span" tone="subdued" fontWeight="bold">
                  {targetNumber} khách, hoàn thành {caculatePercent}%
                </Text>
              </InlineStack>

              <InlineStack gap={'200'} blockAlign="center" align="space-between">
                <Text as="span">Số khách đạt được</Text>
                <Text as="span" tone="subdued" fontWeight="bold">
                  {String(gotNumber) + '/' + String(customerCampaignSingleData?.campaign_target)}
                </Text>
              </InlineStack>

              <InlineStack gap={'200'} blockAlign="center" align="space-between">
                <Text as="span">Số đơn đạt được</Text>
                <Text as="span" tone="subdued" fontWeight="bold">
                  {String(gotOrderNumber)}
                </Text>
              </InlineStack>

              <br />

              <UserAssignToCustomerCampaign />

              <br />

              <Progressbar size="normal" unit="đơn" title="Bảng ghi công" data={progressData} />
            </BlockStack>

            <CustomerBirthDayList customer_campaign={Param} />
            <br />
            <br />
            <Text as="h6" variant="headingMd">
              Mô tả
            </Text>
            <Text as="p">{customerCampaignSingleData?.campaign_description}</Text>
          </div>

          <BlockStack gap={'200'}>
            <InlineGrid gap="400" columns={{
            xs: 1,
            sm: 1,
            md: 1,
            lg: 3,
            xl: 3
          }} alignItems="center">
              <Card>
                <Text as="p" tone="critical">
                  Đơn hàng
                </Text>
                <Text as="h2" variant="heading2xl">
                  {gotOrderNumber} đơn
                </Text>
                <div style={{
                margin: '5px 0'
              }}>
                  <Text as="p" tone="subdued">
                    Tổng lợi nhuận: {helpers.formatNumber(total_profit, ',')} vnd
                  </Text>
                </div>
              </Card>

              <Card>
                <Text as="p" tone="critical">
                  Khách hàng
                </Text>
                <Text as="h2" variant="heading2xl">
                  {gotNumber}
                </Text>
                <div style={{
                margin: '5px 0'
              }}>
                  <Text as="p" tone="subdued">
                    Mục tiêu {targetNumber} khách
                  </Text>
                </div>
              </Card>

              <Card>
                <Text as="p" tone="critical">
                  Hoàn thành
                </Text>
                <Text as="h2" variant="heading2xl">
                  {caculatePercent}%
                </Text>
                <div style={{
                margin: '5px 0'
              }}>
                  <Text as="p" tone="subdued">
                    Đạt được {gotNumber} / {targetNumber}
                  </Text>
                </div>
              </Card>
            </InlineGrid>

            <br />
            <br />

            <CampaignAnalytics />
          </BlockStack>
        </InlineGrid>
        <br />
        <Divider />
        <br />

        <BlockStack gap="400">
          <div className="custom_tabs" style={{
          margin: '0 -15px'
        }}>
            <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}></Tabs>
            <div style={{
            margin: '15px'
          }}>
              {tabs[tabselected].panelID === 'customer' && <CampaignCustomerList campaign_id={Param} />}
              {tabs[tabselected].panelID === 'orders' && <CampaignOrderList campaign_id={Param} />}
              {tabs[tabselected].panelID === 'comments' && <Comments objectId={Param} commentType={'campaign_comment'} title={'Thảo luận'} />}
            </div>
          </div>
        </BlockStack>

        <br />
        <br />
      </Page>

      <CustomerCampaignNewModel onClose={() => setShowModelEdit(false)} show={showModelEdit} mode="edit" />

      <Hotkeys keyName="shift+enter" onKeyDown={(keyname: any, event: any, _handler) => {
      event.preventDefault();
      setNewCustomerModal(true);
    }} />
    </>;
}