import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedUser_group } from "./user_group.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
interface TypedUser_to_group {
    id?: string | bigint;
    user_id?: string | bigint;
    user_group_id?: string | bigint;
    createdAt?: string | bigint;
    user_group_data?: TypedUser_group
}


export default interface TypedUser_to_group_admin {
    "id"?: string,
    "user_id"?: string,
    "user_group_id"?: string,
    "hash"?: string,
    "createdAt"?: string,
    "user_data"?: {
        "user_id"?: string,
        "user_phonenumber"?: string,
        "user_email"?: string,
        "display_name"?: string,
        "user_avatar"?: string,
        "user_status"?: number,
        "lastActive"?: string,
    }
}

type IQuery = TypedUser_to_group & IQueryParams;


/*
* List of all app
*/
export function useGetUser_to_groups(user_id: string) {
    return useQuery({
        queryKey: ["user_to_group/fetch_entity_list"],
        queryFn: () => axios.get<TypedUser_to_group[]>(`/user_to_group/${user_id}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

/** For admin only ... Fetch all member in a group ... */
export function useGetUsers_in_group(entity: IQuery) {
    let query = helpers.buildEndUrl(entity);
    return useQuery({
        queryKey: ["user_to_group/fetch_entity_list"],
        queryFn: () => axios.get<TypedUser_to_group_admin[]>(`/user_to_group${query}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useCreateUser_to_group() {
    return useMutation({
        mutationKey: ['user_to_group/create_entity'],
        mutationFn: (entity: TypedUser_to_group) => axios.post<TypedUser_to_group>(`/user_to_group`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_to_group/fetch_entity_list'] });
        }
    });
}


export function useDeleteUser_to_group() {
    return useMutation({
        mutationKey: ['user_to_group/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`/user_to_group/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_to_group/fetch_entity_list'] });
        }

    });
}
