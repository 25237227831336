import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'config/store';
import CustomerCampaignNewModel from './customer_campaign.modal';
import Hotkeys from 'react-hot-keys';
import { getEntities } from 'store/customer_campaign.store.reducer';
import { PlusIcon } from '@shopify/polaris-icons';
import { DataTable, LegacyCard, Page, Text, Loading, Link, Tooltip, ProgressBar, Badge, Avatar } from '@shopify/polaris';
import EmptyState from 'components/EmptyState';
import helpers from 'helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import __, { ___ } from 'languages/index';
import CustomerCampaignFilter from './filter';
import Pagination from 'components/pagination';
import AddNewCustomerForCustomerCampaign from 'components/addNewCustomer.modal';
export default function CustomerCampaignListAll() {
  const entities = useAppSelector(state => state.customer_campaign.entities);
  const loading = useAppSelector(state => state.customer_campaign.loading);
  const totalItems = useAppSelector(state => state.customer_campaign.totalItems);
  const updating = useAppSelector(state => state.customer_campaign.updating);
  // const updateSuccess = useAppSelector((state) => state.customer_campaign.updateSuccess);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  /**
   * Add new Customer ...
   */

  const [newCustomerModal, setNewCustomerModal] = useState(false);

  /**
   * New model
   */

  const [newModelactive, setNewModelactive] = useState(false);
  const toggleNewActive = useCallback(() => setNewModelactive(active => !active), []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    // console.log(_value, '_value');
    // return;
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    dispatch(getEntities(mainQuery));
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Customer Campaign', '/customer_campaign' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   *
   * @param post_id
   */
  const shortcutActions = (campaignID: any) => {
    history('/customer_campaign/view/' + campaignID);
  };
  const emptyData = <EmptyState>
      <p>{__('no_customer_campaign_to_show')}</p>
    </EmptyState>;
  const avatarListing = (array: any[]) => {
    const maxVisible = 3;
    return array.map((el?: any, index?: number) => {
      if (index < maxVisible) {
        return <Avatar key={index} size="sm" initials={(el?.assignee?.display_name).charAt(0)} source={el?.assignee?.user_avatar} />;
      } else if (index === maxVisible) {
        return <span key={index} className="more-count">
              &nbsp; +{array.length - maxVisible} more
            </span>;
      } else {
        return null;
      }
    }).filter(el => el !== null);
  };
  const renderItem = (entity: any) => {
    let caculatePercent = helpers.caculatePercent(entity?.campaign_target, entity?.campaign_count);
    let targetNumber = helpers.getPad(entity?.campaign_target ?? 0);
    let gotNumber = helpers.getPad(entity?.campaign_count ?? 0);
    let deadline = helpers.subtractDate(entity.campaign_deadline);
    let deadlineBadge = Number(deadline) <= 0 ? <Badge tone="success">{Math.abs(Number(deadline)) + ' ngày'}</Badge> : <Badge tone="warning">Hết hạn</Badge>;
    return [<Link key={entity.campaign_id} onClick={() => shortcutActions(entity.campaign_id)}>
        <div style={{
        minWidth: '200px'
      }}>
          {entity.campaign_description ? <Tooltip content={entity.campaign_description}>
              <Text fontWeight="bold" as="p">
                {entity.campaign_name}
              </Text>
            </Tooltip> : <Text fontWeight="bold" as="p">
              {entity.campaign_name}
            </Text>}
        </div>
      </Link>, <div className="list_user_loosing_space">{avatarListing(entity?.user_to_campaign || [])}</div>, <div style={{
      width: 125
    }}>
        {entity.campaign_target > 0 ? <Tooltip content={`${gotNumber} / ${targetNumber} khách`}>
            <ProgressBar progress={caculatePercent} size="medium" tone="success" />
          </Tooltip> : null}
      </div>, targetNumber, gotNumber, entity.campaign_order_count, deadlineBadge, <>
        {entity.product ? <Tooltip content={helpers.formatNumber(entity.product?.product_price, ',') + ' đ'}>
            <Text as="span">{entity.product?.product_name ?? '-'}</Text>
          </Tooltip> : '-'}
      </>, entity.user.user_login, helpers.subtractTimeHistory(entity.createdAt)
    // dateandtime.format(new Date(Number(entity.createdAt)), 'HH:mm DD-MM-YYYY' )
    ];
  };
  const actualPage = <>
      <AddNewCustomerForCustomerCampaign show={newCustomerModal} default_campaign={null} onClose={() => {
      setNewCustomerModal(false);
    }} />

      <Page fullWidth title="Chiến dịch khách hàng" subtitle="Tạo hoặc quản lý một chiến dịch khách hàng" secondaryActions={[{
      content: 'Thêm khách',
      icon: PlusIcon,
      onAction: () => setNewCustomerModal(true)
    }, {
      content: 'Tạo chiến dịch',
      icon: PlusIcon,
      onAction: toggleNewActive
    }]}>
        <LegacyCard>
          <CustomerCampaignFilter loading={loading} onCallback={filterCallback} />
          {entities && entities.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric', 'numeric', 'text', 'text', 'text']} headings={['Chiến dịch', 'Thành viên', '', 'Mục tiêu', 'Khách', 'Chuyển đổi', 'Hạn chót', 'Sản phẩm', 'Ng.tạo', 'Ngày tạo']} hideScrollIndicator rows={entities.map(renderItem)} footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} /> : emptyData}
        </LegacyCard>
        <br />
        {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
      </Page>
    </>;
  return <>
      {loading || updating ? <Loading /> : null}

      <CustomerCampaignNewModel onClose={() => setNewModelactive(false)} show={newModelactive} mode="new" />

      {entities === null ? <SkeletonLoading fullWidth /> : actualPage}

      <Hotkeys keyName="shift+enter" onKeyDown={(keyname: any, event: any, _handler) => {
      event.preventDefault();
      toggleNewActive();
    }} />
    </>;
}