import { AutoSelection, Badge, BlockStack, Box, Link, Listbox, SkeletonBodyText, Text } from '@shopify/polaris';
import Lottie from 'lottie-react';
import { useGetOrderSidebar } from 'queries/orders.query';
import React, { useCallback, useEffect, useState } from 'react';
import user_referrer_placeholder from 'media/lottie_files/user_referrer_placeholder.json';
import __helpers from 'helpers/index';
import { useNavigate } from 'react-router-dom';
import useDidMountEffect from 'components/useDidMountEffect';
// import useWindowDimensions from 'components/useWindowDimensions';

export default function OrderSidebarQuickSuft({
  order_id
}) {
  const {
    data,
    isFetching,
    refetch
  } = useGetOrderSidebar(order_id);
  useDidMountEffect(() => {
    refetch();
  }, [order_id]);
  const history = useNavigate();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(order_id);
  useEffect(() => {
    setSelectedSegmentIndex(order_id);
  }, [order_id]);
  const handleSegmentSelect = (segmentIndex: string) => {
    setSelectedSegmentIndex(Number(segmentIndex));
  };
  const ActiveOptionChangeCallback = useCallback((value: string, domID: string) => {
    history('/order/view/' + value);
  }, []);

  //   const { height } = useWindowDimensions();

  return <Box padding={'200'} background="bg" minHeight="100%">
      <BlockStack gap="200">
        {isFetching ? <>
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
          </> : <>
            {/* <Text as="h3" variant="headingMd">
              Duyệt nhanh các đơn
             </Text> */}

            {__helpers.isEmpty(data || []) ? <BlockStack align="center" inlineAlign="center">
                <Lottie className="user_referrer_placeholder" animationData={user_referrer_placeholder} loop />
                <Text as="p" variant="headingMd">
                  Chỗ này trống trơn!
                </Text>
              </BlockStack> :
        //   <Scrollable shadow style={{ height: height + 'px' }}>
        <Listbox enableKeyboardControl accessibilityLabel="Order quick list" onSelect={handleSegmentSelect} autoSelection={AutoSelection.FirstSelected} onActiveOptionChange={ActiveOptionChangeCallback}>
                {Array.isArray(data) && data?.map((element, index) => {
            return <Listbox.Option key={`abc_` + index} selected={selectedSegmentIndex === element.order_id} value={element.order_id}>
                        {`${element.order_pnr} - ${element.order_id}`}
                      </Listbox.Option>;
          })}
              </Listbox>
        //   </Scrollable>
        }
          </>}
      </BlockStack>
    </Box>;
}