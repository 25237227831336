import { compiledBlock as _compiledBlock } from "million/react";
const ListVoucher_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "ListVoucher_1",
  portals: ["v0", "v1"]
});
const ListVoucher = function ListVoucher() {
  const history = useNavigate();
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const {
    data,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetVouchers(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedVoucher[]>([]);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setRecords(entities);
      setTotalItems(totalItems);
    }
  }, [data]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Voucher', '/voucher' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);
  return /*@million jsx-skip*/<ListVoucher_1 v0={<Helmet>
        <title>Quản lý mã khuyến mại</title>
      </Helmet>} v1={<Page narrowWidth title="Quản lý mã khuyến mại" primaryAction={{
    content: 'Thêm mới Voucher',
    icon: PlusIcon,
    onAction: () => history('/voucher/new')
  }}>
        <Card padding={'0'}>
          <SimpleFilter onCallback={filterCallback} options={[{
        label: 'Mã vận chuyển',
        field: 'voucher_category',
        value: 'shipping'
      }, {
        label: 'Mã đơn hàng',
        field: 'voucher_category',
        value: 'order'
      }]} loading={false} sortField={[{
        label: 'Số lượng đã sử dụng',
        field: 'voucher_count'
      }, {
        label: 'Số lượng cho phép',
        field: 'voucher_count_max'
      }]} />
        </Card>
        <br />
        <BlockStack gap="400">
          {records && helpers.isEmpty(records) && !loading && <BlockStack gap={'400'} align="center" inlineAlign="center">
              <Text as="h3" variant="headingMd">
                Chỗ này trống trơn
              </Text>
              <div style={{
          maxWidth: 250
        }}>
                <Lottie animationData={voucher_empty} loop={true} />
              </div>
            </BlockStack>}
          {records && Array.isArray(records) && records.map((element, index) => {
        let {
          voucher_id,
          voucher_valid_to,
          voucher_name,
          voucher_excerpt,
          voucher_code,
          voucher_count,
          voucher_count_max,
          voucher_thumbnail_to_media,
          is_active
        } = element;
        return <div className={`voucher voucher_${is_active ? 'active' : 'deactive'}`} key={`voucher_` + index}>
                  <div className="voucher-date">
                    <Link url={`/voucher/view/${voucher_id}`}>
                      Hạn đến {dateandtime.format(new Date(Number(voucher_valid_to)), 'HH:mm DD-MM-YYYY')}
                    </Link>
                  </div>
                  <div className="voucher-content">
                    <div className="voucher-value">{voucher_name}</div>
                    <div className="voucher-icon">
                      {voucher_thumbnail_to_media ? <Thumbnail source={helpers.getMediaLink(voucher_thumbnail_to_media.media_thumbnail ? voucher_thumbnail_to_media.media_thumbnail['128x128'] : voucher_thumbnail_to_media.media_url)} alt={''} /> : <img src={voucher_default_image} alt="Gift Icon" />}
                    </div>
                  </div>
                  <div style={{
            width: 225
          }}>
                    <Tooltip content={`Đã dùng ${voucher_count} trong tổng số tối đa ${voucher_count_max}`}>
                      <ProgressBar progress={Math.floor(voucher_count / voucher_count_max * 100)} size="small" />
                    </Tooltip>
                  </div>
                  <p className="voucher-terms">{voucher_excerpt}</p>
                </div>;
      })}
        </BlockStack>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>} />;
};
import { block as _block } from "million/react";
import { BlockStack, Card, Text, InlineStack, Link, Page, ProgressBar, Thumbnail, Tooltip } from '@shopify/polaris';
import SimpleFilter from 'components/simpleFilter';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PlusIcon } from '@shopify/polaris-icons';
import 'media/css/voucher.scss';
import voucher_default_image from 'media/images/voucher_default_image.png';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import { useNavigate } from 'react-router-dom';
import { TypedVoucher, useGetVouchers } from 'queries/voucher.query';
import dateandtime from 'date-and-time';
import voucher_empty from 'media/lottie_files/voucher_empty.json';
import Lottie from 'lottie-react';
export default ListVoucher;