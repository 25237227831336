import { Button, LegacyCard, DataTable, EmptyState, Layout, Page, Text, Toast, Box, DescriptionList, Badge } from '@shopify/polaris';
import Parser from 'html-react-parser';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../config/store';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import emptyIMG from '../../media/images/empty.png';
import { clearError, getEntity, updateEntity, deleteEntity, createEntity, getOutgoingLog, reset } from '../../store/outgoing_webhook.store.reducer';
import OutgoingWebhookModal from './outgoing_webhook.create';
import helpers from 'helpers';
import DeleteConfirm from 'components/deleteConfirm';
import SkeletonLoading from 'components/skeletonPageLoading';
export default function Edit_outgoing_webhook() {
  const entity = useAppSelector(state => state.outgoing_webhook.entity);
  const entities_log = useAppSelector(state => state.outgoing_webhook.entities_log);
  const totalLogItems = useAppSelector(state => state.outgoing_webhook.totalLogItems);
  const updating = useAppSelector(state => state.outgoing_webhook.updating);
  const loading = useAppSelector(state => state.outgoing_webhook.loading);
  const errorMessage = useAppSelector(state => state.outgoing_webhook.errorMessage);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [notification, setNotification] = useState(null);
  let {
    outgoing_webhook_slug,
    search
  } = useParams();
  let StringQuery: any = helpers.ExtractUrl(search) || false;
  const [mainQuery, setMainQuery] = useState({
    ...{
      query: '',
      page: 1,
      limit: 20,
      outgoing_webhook_id: outgoing_webhook_slug,
      sort: 'createdAt:desc'
    },
    ...StringQuery
  });
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const [newModelactive, setNewModelactive] = useState(false);
  const toggleNewActive = useCallback(() => setNewModelactive(active => !active), []);
  useEffect(() => {
    if (outgoing_webhook_slug) {
      dispatch(getEntity(outgoing_webhook_slug));
      dispatch(getOutgoingLog(mainQuery));
    }
  }, [mainQuery]);
  const toggleActive = useCallback(() => {
    setNotification(null);
    dispatch(clearError());
  }, []);
  useEffect(() => {
    if (errorMessage) {
      setNotification(errorMessage);
      dispatch(clearError());
    }
  }, [errorMessage]);
  function _deleteEntityAction() {
    setOpenModal(true);
  }
  function onModalAgree() {
    dispatch(deleteEntity(outgoing_webhook_slug));
  }
  const emptyData = <EmptyState heading="No log here!" image={emptyIMG}>
      <p>Thử lại sau!</p>
    </EmptyState>;
  const toastMarkup = notification ? <Toast content={notification} onDismiss={toggleActive} /> : null;
  function convertToRow(entity: any) {
    return [entity.log_id, entity.log_method, dateandtime.format(new Date(Number(entity.createdAt)), 'DD-MM-YYYY HH:mm:ss')];
  }
  function takeEventsFromEntity(entity: any) {
    let allEvent = [];
    if (typeof entity.outgoing_webhook_action !== 'undefined') for (let element of entity.outgoing_webhook_action) {
      allEvent.push(element.action_key);
    }
    return allEvent.join(', ');
  }
  const Actual_page = entity || !outgoing_webhook_slug ? <>
        <Page title="Outgoing Webhook" backAction={{
      content: 'outgoing_webhook list',
      url: '/outgoing_webhook'
    }} primaryAction={{
      content: 'Chỉnh sửa',
      disabled: false,
      onAction: toggleNewActive
    }}>
          <Layout>
            <Layout.Section>
              <LegacyCard>
                {entities_log.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text']} headings={['ID', 'Method', 'Date']} rows={entities_log?.map(convertToRow)} /> : emptyData}
              </LegacyCard>
              {totalLogItems > mainQuery.limit ? <Pagination TotalRecord={totalLogItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
              <br />
              <br />
              <br />
            </Layout.Section>

            <Layout.Section variant="oneThird">
              <LegacyCard>
                <LegacyCard.Section title="Webhook details">
                  <Box>
                    <p style={{
                  fontWeight: 'bold'
                }}>{entity.webhook_title}</p>
                    <p>{Parser(entity.api_description || 'No description!')}</p>
                    <br />

                    <DescriptionList gap="tight" items={[{
                  term: 'Method',
                  description: String(entity.webhook_method).toUpperCase()
                }, {
                  term: 'Webhook URL',
                  description: String(entity.webhook_url)
                }, {
                  term: 'Created At',
                  description: dateandtime.format(new Date(Number(entity.createdAt)), 'DD-MM-YYYY HH:mm:ss')
                }, {
                  term: 'Updated At',
                  description: entity.updatedAt ? dateandtime.format(new Date(Number(entity.updatedAt)), 'DD-MM-YYYY HH:mm:ss') : <>
                              {' '}
                              <Badge></Badge> <Badge></Badge>{' '}
                            </>
                }, {
                  term: 'Last Open',
                  description: entity.lastOpen ? dateandtime.format(new Date(Number(entity.lastOpen)), 'DD-MM-YYYY HH:mm:ss') : <>
                              {' '}
                              <Badge></Badge> <Badge></Badge>{' '}
                            </>
                }, {
                  term: 'Success Open',
                  description: entity.successOpen ? dateandtime.format(new Date(Number(entity.successOpen)), 'DD-MM-YYYY HH:mm:ss') : <>
                              {' '}
                              <Badge></Badge> <Badge></Badge>{' '}
                            </>
                }, {
                  term: 'Fail Open',
                  description: entity.failOpen ? dateandtime.format(new Date(Number(entity.failOpen)), 'DD-MM-YYYY HH:mm:ss') : <>
                              {' '}
                              <Badge></Badge> <Badge></Badge>{' '}
                            </>
                }]} />
                    <br />
                    <Text fontWeight="bold" as="p">
                      Events will firing{' '}
                    </Text>
                    <p>
                      <code>{takeEventsFromEntity(entity)}</code>
                    </p>
                  </Box>
                </LegacyCard.Section>

                <LegacyCard.Section>
                  <Button onClick={() => _deleteEntityAction()}>DELETE</Button>
                </LegacyCard.Section>
              </LegacyCard>
            </Layout.Section>
          </Layout>
        </Page>
        <OutgoingWebhookModal show={newModelactive} onClose={() => setNewModelactive(false)} />
      </> : emptyData;
  return <>
      {toastMarkup}
      <DeleteConfirm title="Bạn muốn xóa webhook này?" show={openModal} onClose={mode => {
      mode === true ? onModalAgree() : setOpenModal(false);
    }} />
      {loading ? <SkeletonLoading /> : Actual_page}
    </>;
}