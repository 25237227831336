import { Button, InlineStack, IndexFilters, IndexFiltersProps, Link, useSetIndexFiltersMode, Text, Select, IndexFiltersMode } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import helpers from '../../helpers';
import { useDeleteUserMeta, useGetUserMeta, useUpdateUserMeta } from 'queries/user_meta.query';
import useDidMountEffect from 'components/useDidMountEffect';
import __ from 'languages/index';
import * as constants from 'constant';
import { useGetProductCategories } from 'queries/product_category.query';
import { useGetProductBrands } from 'queries/brand.query';
type TypedSort = {
  label: string;
  field: string;
};
export default function ProductFilter({
  sortField,
  onCallback,
  loading
}: {
  onCallback: (output: any) => void;
  loading: boolean;
  sortField: TypedSort[];
}) {
  const {
    mutateAsync: getEntity,
    isPending: _loading
  } = useGetUserMeta();
  const {
    mutateAsync: updateEntity,
    isPending: updating
  } = useUpdateUserMeta();
  const {
    mutateAsync: deleteEntity
  } = useDeleteUserMeta();
  const key_for_saving_setting = `tab_product_index_`;

  /**
   * Load data
   */

  const getItem = useCallback(async (meta_key: string) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await getEntity(meta_key);
  }, []);
  const removeItem = useCallback(async (meta_key: string) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await deleteEntity(meta_key);
  }, []);
  const updateItem = useCallback(async (meta_key: string, meta_value: any) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await updateEntity({
      meta_key: meta_key,
      meta_value: meta_value
    });
  }, []);

  /**
   * Load data
   */

  const rootSearchPath = window.location.search;
  let mainQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const {
    query,
    product_status,
    sort
  } = mainQuery;
  let category_id = mainQuery['product_to_category.category_id'];
  let brand_id = mainQuery['product_to_brand.brand_id'];
  const [sortSelected, setSortSelected] = useState<string[]>([helpers.colonToOldSort(sort) || 'createdAt, desc']);
  const [queryValue, setQueryValue] = useState(query || '');
  const [categoryID, setCategoryID] = useState(category_id || '');
  const [brandID, setBrandID] = useState(brand_id || '');
  const [productStatus, setProductStatus] = useState(product_status || '');
  const [selected, setSelected] = useState(0); // tab selected
  /**
   * Change tab, load new data from url down to filter
   */
  const reloadDataDownFromURL = (__: any) => {
    const {
      query,
      product_status
    } = __;
    let category_id = mainQuery['product_to_category.category_id'];
    let brand_id = mainQuery['product_to_brand.brand_id'];
    setProductStatus(product_status || '');
    setQueryValue(query || '');
    setCategoryID(category_id || '');
    setBrandID(brand_id || '');
  };

  /***************************************************************************
  /***************************************************************************
  /***************************************************************************
   * FILTERs
   */

  function getCurrentSetting(): string {
    const rootSearchPath = window.location.search;
    let mainQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
    return JSON.stringify(mainQuery);
  }

  /**
   * reduce loading,
   */

  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onCallback?.call(this, _value);
    }, 200);
  }, []);
  useDidMountEffect(() => {
    if (!sortSelected) return;
    let sortString = '';
    if (Array.isArray(sortSelected)) {
      sortString = sortSelected.join('');
    } else {
      sortString = sortSelected;
    }
    onChangeMainQueryCallback({
      sort: helpers.oldSortToColon(sortString)
    });
  }, [sortSelected]);

  /**
   * Hold array of all tab
   */
  const [itemStrings, setItemStrings] = useState(['ALL']);
  useEffect(() => {
    getItem('product_tabs').then(response => {
      if (helpers.isEmpty(response)) return;
      setItemStrings(response || []);
    }).catch(e => console.log(e, 'e_8d'));
  }, []);

  // save name to
  useDidMountEffect(() => {
    let tabsToString = JSON.stringify(itemStrings);
    updateItem('product_tabs', tabsToString);
  }, [itemStrings]);

  /**
   * click change tab ...
   * @param index
   * @returns
   */
  const changeTabFilterAction = useCallback(async (index: number, name: string) => {
    if (index === 0) {
      reloadDataDownFromURL({});
      return onChangeMainQueryCallback(false);
    }
    onChangeMainQueryCallback(false);
    await helpers.sleep(100);
    getItem(key_for_saving_setting + name).then((response: any) => {
      if (!response) return;
      onChangeMainQueryCallback(response);
      reloadDataDownFromURL(response);
    }).catch(_ => {});
  }, []);
  const deleteView = (index: number, value: string) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
    removeItem(key_for_saving_setting + value);
  };
  const duplicateView = async (name: string) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    // clone setting
    updateItem(key_for_saving_setting + name, getCurrentSetting());
    return true;
  };
  const tabs: any[] = Array.isArray(itemStrings) && itemStrings?.map((item, index) => ({
    content: item,
    index,
    onAction: () => {
      changeTabFilterAction(index, item);
    },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions: index === 0 ? [] : [{
      type: 'rename',
      onAction: () => {},
      onPrimaryAction: async (value: string): Promise<boolean> => {
        const newItemsStrings = tabs?.map((item, idx) => {
          if (idx === index) {
            return value;
          }
          return item.content;
        });
        setItemStrings(newItemsStrings);
        return true;
      }
    }, {
      type: 'duplicate',
      onPrimaryAction: async (value: string): Promise<boolean> => {
        duplicateView(value);
        return true;
      }
    }, {
      type: 'delete',
      onPrimaryAction: async (value: string) => {
        deleteView(index, value);
        return true;
      }
    }]
  }));
  const {
    mode,
    setMode
  } = useSetIndexFiltersMode(IndexFiltersMode.Default); // default search field
  /** current_search_keyword */
  useEffect(() => {
    if (query && query !== '') {
      setMode(IndexFiltersMode.Filtering);
    }
    if (product_status && product_status !== '') {
      setMode(IndexFiltersMode.Filtering);
    }
    if (brandID) {
      setMode(IndexFiltersMode.Filtering);
    }
    if (categoryID) {
      setMode(IndexFiltersMode.Filtering);
    }
  }, [query, product_status, brandID, categoryID]);
  const handleFiltersQueryChange = useCallback((value: string) => {
    setQueryValue(value);
    onChangeMainQueryCallback({
      query: value,
      page: 1
    });
  }, []);

  /**
   * Xoa hoac huy form search ...
   */
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('');
    onChangeMainQueryCallback({
      query: '',
      page: 1
    });
  }, []);
  const [sortOptions, setSortOptions] = useState<IndexFiltersProps['sortOptions']>([{
    label: 'Ngày tạo',
    value: 'createdAt, asc',
    directionLabel: __('ascending')
  }, {
    label: 'Ngày tạo',
    value: 'createdAt, desc',
    directionLabel: __('descending')
  }]);
  useEffect(() => {
    let _s: any[] = sortOptions;
    if (sortField) {
      for (let s of sortField) {
        _s = [..._s, ...[{
          label: s.label || '_',
          value: s.field + `, asc`,
          directionLabel: __('ascending')
        }, {
          label: s.label || '_',
          value: s.field + `, desc`,
          directionLabel: __('descending')
        }]];
      }
      setSortOptions(_s);
    }
  }, [sortField]);
  const handleUserRoleWithChange = useCallback((value: string) => {
    setProductStatus(value);
    onChangeMainQueryCallback({
      product_status: value,
      page: 1
    });
  }, []);
  const handleUserRoleWithRemove = useCallback(() => {
    setProductStatus('');
    onChangeMainQueryCallback({
      product_status: '',
      page: 1
    });
  }, []);
  const handleCategoryIDWithChange = useCallback((value: string) => {
    setCategoryID(value);
    onChangeMainQueryCallback({
      'product_to_category.category_id': value,
      page: 1
    });
  }, []);
  const handleCategoryIDWithRemove = useCallback(() => {
    setCategoryID('');
    onChangeMainQueryCallback({
      'product_to_category.category_id': '',
      page: 1
    });
  }, []);
  const handleBrandIDWithChange = useCallback((value: string) => {
    setBrandID(value);
    onChangeMainQueryCallback({
      'product_to_brand.brand_id': value,
      page: 1
    });
  }, []);
  const handleBrandIDWithRemove = useCallback(() => {
    setBrandID('');
    onChangeMainQueryCallback({
      'product_to_brand.brand_id': '',
      page: 1
    });
  }, []);
  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleUserRoleWithRemove();
    handleCategoryIDWithRemove();
    handleBrandIDWithRemove();
    onChangeMainQueryCallback(false);
  }, [handleBrandIDWithRemove, handleCategoryIDWithRemove, handleQueryValueRemove, handleUserRoleWithRemove, onChangeMainQueryCallback]);

  /** Load category về */
  /**  Lưu cả array lại để tẹo hiển thị  */
  const [allProductCategoryData, setAllProductCategoryData] = useState([]);
  const {
    data: entities
  } = useGetProductCategories({
    limit: 100,
    category_status: 1,
    sort: 'createdAt:desc'
  });
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      setAllProductCategoryData(body);
    }
  }, [entities]);
  /** END Load category về */

  /** Load category về */
  /**  Lưu cả array lại để tẹo hiển thị  */
  const [allProductBrandData, setAllProductBrandData] = useState([]);
  const {
    data: brandEntities
  } = useGetProductBrands({
    limit: 100,
    sort: 'createdAt:desc'
  });
  useEffect(() => {
    if (brandEntities) {
      let {
        body,
        totalItems
      } = brandEntities;
      setAllProductBrandData(body);
    }
  }, [brandEntities]);

  /** END Load category về */

  function disambiguateLabel(key: string, value: string | any): string {
    switch (key) {
      case 'keyword':
        return `Từ khóa: ${value}`;
      case 'product_status':
        return `Tình trạng sản phẩm: ${value === '0' ? 'Ngừng bán' : value === '' ? 'Tất cả tình trạng' : 'Đang bán'}`;
      case 'product_to_category.category_id':
        return `Chuyên mục: ${allProductCategoryData?.find(el => el.category_id === value)?.category_name || 'Tất cả'}`;
      case 'product_to_brand.brand_id':
        console.log(allProductBrandData, 'allProductBrandData');
        console.log(value, 'value allProductBrandData');
        return `Nhãn hiệu: ${allProductBrandData?.find(el => el.brand_id === value)?.brand_name || 'Tất cả'}`;
      default:
        return value as string;
    }
  }
  const filters = [{
    key: 'product_status',
    label: 'Tình trạng bán',
    filter: <Select label="" value={productStatus} options={[{
      label: __('Tất cả tình trạng'),
      value: ''
    }, {
      label: __('Ngừng bán'),
      value: '0'
    }, {
      label: __('Đang bán'),
      value: '1'
    }]} onChange={handleUserRoleWithChange} />,
    shortcut: true
  }, {
    key: 'product_to_category.category_id',
    label: 'Chuyên mục',
    filter: <Select label="" value={categoryID} options={[...[{
      label: __('Tất cả chuyên mục'),
      value: ''
    }], ...allProductCategoryData.map(el => {
      return {
        label: el.category_name,
        value: el.category_id
      };
    })]} onChange={handleCategoryIDWithChange} />,
    shortcut: true
  }, {
    key: 'product_to_brand.brand_id',
    label: 'Nhãn hiệu',
    filter: <Select label="" value={brandID} options={[...[{
      label: __('Tất cả nhãn hiệu'),
      value: ''
    }], ...allProductBrandData.map(el => {
      return {
        label: el.brand_name,
        value: el.brand_id
      };
    })]} onChange={handleBrandIDWithChange} />,
    shortcut: true
  }];
  const appliedFilters: IndexFiltersProps['appliedFilters'] = [];

  // if (!helpers.isEmpty(userRole)) {
  appliedFilters.push({
    key: 'product_status',
    label: disambiguateLabel('product_status', productStatus),
    onRemove: handleUserRoleWithRemove
  });
  appliedFilters.push({
    key: 'product_to_category.category_id',
    label: disambiguateLabel('product_to_category.category_id', categoryID),
    onRemove: handleCategoryIDWithChange
  });
  appliedFilters.push({
    key: 'product_to_brand.brand_id',
    label: disambiguateLabel('product_to_brand.brand_id', brandID),
    onRemove: handleBrandIDWithChange
  });
  // }

  /**
   * Lưu lại ...
   * @param value
   * @returns
   */
  const onCreateNewView = async (value: string) => {
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    updateItem(key_for_saving_setting + value, getCurrentSetting());
    return true;
  };
  const onHandleSave = useCallback(async () => {
    if (!itemStrings[selected]) return;
    updateItem(key_for_saving_setting + itemStrings[selected], getCurrentSetting());
    return true;
  }, [selected, itemStrings]);
  const primaryActionForFilterFields: IndexFiltersProps['primaryAction'] = selected === 0 ? {
    type: 'save-as',
    onAction: onCreateNewView,
    disabled: false,
    loading: false
  } : {
    type: 'save',
    onAction: onHandleSave,
    disabled: false,
    loading: false
  };

  /***************************************************************************
    /***************************************************************************
    /***************************************************************************
     * FILTERs
     */

  return <>
      <IndexFilters loading={loading || _loading || updating} sortOptions={sortOptions} sortSelected={sortSelected} queryValue={queryValue} queryPlaceholder="Tìm kiếm" onQueryChange={handleFiltersQueryChange} onQueryClear={handleQueryValueRemove} onSort={setSortSelected} primaryAction={primaryActionForFilterFields} cancelAction={{
      onAction: () => {},
      disabled: false,
      loading: false
    }} tabs={tabs} selected={selected} onSelect={setSelected} canCreateNewView={true} onCreateNewView={onCreateNewView} onClearAll={handleFiltersClearAll} mode={mode} setMode={setMode} filters={filters} appliedFilters={appliedFilters} />
      {mode === 'DEFAULT' ? <div className="Polaris-Filters__FiltersWrapper Polaris-Filters__FiltersWrapperWithAddButton filterWrapper">
          <div className="Polaris-Filters__FiltersInner">
            {!helpers.isEmpty(queryValue) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('keyword', queryValue)}
              </span> : null}

            {productStatus !== '' ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('product_status', productStatus)}
              </span> : null}

            {categoryID !== '' ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('product_to_category.category_id', categoryID)}
              </span> : null}
            {brandID !== '' ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('product_to_brand.brand_id', brandID)}
              </span> : null}

            {helpers.isEmpty(queryValue) && helpers.isEmpty(productStatus) && helpers.isEmpty(categoryID) && helpers.isEmpty(brandID) ? <InlineStack blockAlign="center" align="start" wrap={false}>
                <Link removeUnderline onClick={() => setMode('FILTERING' as any)}>
                  {'   '}{' '}
                  <Text as="span" variant="bodySm" tone="subdued">
                    Mở bộ lọc (hoặc bấm nút F)
                  </Text>
                </Link>
              </InlineStack> : <Button variant="plain" size="micro" onClick={handleFiltersClearAll}>
                xóa hết
              </Button>}
          </div>
        </div> : null}
    </>;
}