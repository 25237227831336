import React, { useEffect } from 'react';
import { Link, FormLayout, TextField, Form, InlineStack, Button } from '@shopify/polaris';
import { useState, useCallback } from 'react';
import helpers from 'helpers';
import { useAppDispatch } from 'config/store';
import { SaveIcon, ClipboardIcon } from '@shopify/polaris-icons';
import { TypedUser } from 'interface/user.model';
import { Helmet } from 'react-helmet-async';
import __ from 'languages/index';
import { useNotification } from 'NotificationContext';
import { useSignTest } from 'queries/user.query';
import { useGetOnetimepassword, useSetUserPassword } from 'queries/users.query';
export default function ChangeUserPassword({
  user_data
}: {
  user_data: TypedUser;
}) {
  const {
    mutateAsync: getOnetimepassword
  } = useGetOnetimepassword();
  const {
    mutateAsync: setUserNewPassword
  } = useSetUserPassword();
  const {
    user_id,
    user_email
  } = user_data;
  const dispatch = useAppDispatch();
  const {
    addNotification
  } = useNotification();

  /** Thử signed để nếu chưa signed thì người dùng còn signed */
  useSignTest();

  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */

  //   let useParam =  {} as any;
  //   useParam = useParams();
  //   let Param = useParam.users_slug || false;

  const [matkhaulan1, setMatkhaulan1] = useState('');
  const [matkhaulan2, setMatkhaulan2] = useState('');
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
  const [errorGeneralPasswordPassedOrNot, setErrorGeneralPasswordPassedOrNot] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const matkhau1Callback = useCallback(value => {
    setMatkhaulan1(value);
  }, []);
  const matkhau2Callback = useCallback(value => {
    setMatkhaulan2(value);
  }, []);
  const generatePasswordAuto = () => {
    let autoPass = helpers.getRandomHash(12);
    setMatkhaulan1(autoPass);
    setMatkhaulan2(autoPass);
  };
  useEffect(() => {
    if (!matkhaulan1) return;
    if (matkhaulan1 !== matkhaulan2) {
      setErrorRepeatPassword(true);
    } else {
      setErrorRepeatPassword(false);
    }
    if (matkhaulan1.length < 8) {
      setErrorGeneralPasswordPassedOrNot(true);
    } else {
      if (helpers.getPasswordStrength(matkhaulan1) < 2) {
        setErrorGeneralPasswordPassedOrNot(true);
      } else {
        setErrorGeneralPasswordPassedOrNot(false);
      }
    }
  }, [matkhaulan1, matkhaulan2]);
  const submitChangePassword = async () => {
    if (errorRepeatPassword || errorGeneralPasswordPassedOrNot) return;
    setButtonLoading(true);
    try {
      await setUserNewPassword({
        user_id: String(user_id),
        password: matkhaulan2
      });
      addNotification('info', __('user_password_has_been_successfully_updated.'));
      await helpers.sleep(1000);
    } catch (e) {}
    setButtonLoading(false);
  };

  // getOnetimepasswordLoading
  const [getOnetimepasswordLoading, setGetOnetimepasswordLoading] = useState(false);
  const [onetimepasswordURL, setOnetimepasswordURL] = useState('');
  const getOnetimepasswordCallback = useCallback(async () => {
    try {
      setGetOnetimepasswordLoading(true);
      let data: any = await getOnetimepassword(String(user_id));
      let shortlink = data.payload.data.token;
      setOnetimepasswordURL(process.env.REACT_APP_PUBLIC_URL + '/quick-login/' + shortlink);
      setGetOnetimepasswordLoading(false);
    } catch (e) {}
  }, []);
  return <>
      <Helmet>
        <title>Cập nhật lại mật khẩu</title>
      </Helmet>
      <Form onSubmit={submitChangePassword}>
        <br />
        <FormLayout>
          <TextField label="Mật khẩu mới" onChange={matkhau1Callback} autoComplete="off" value={matkhaulan1} error={errorGeneralPasswordPassedOrNot} helpText="Mật khẩu phải gồm cả chữ và số." />

          <TextField type="text" label="Xác nhận lại mật khẩu" onChange={matkhau2Callback} error={errorRepeatPassword} autoComplete="off" value={matkhaulan2} />
        </FormLayout>
        <div style={{
        padding: '15px 0'
      }}>
          <Link onClick={generatePasswordAuto}>Tạo mật khẩu</Link> tự động. Hoặc có thể tạo đường link đăng nhập một lần:
        </div>

        <TextField type="text" label="" readOnly labelHidden selectTextOnFocus onChange={() => null} autoComplete="off" value={user_email ? onetimepasswordURL : 'Cập nhật email cho khách trước'} connectedRight={<Button size="large" disabled={!user_email} icon={ClipboardIcon} loading={getOnetimepasswordLoading} onClick={getOnetimepasswordCallback}>
              Lấy link
            </Button>} />

        <br />
        <br />
        <InlineStack align="end">
          <Button onClick={submitChangePassword} loading={buttonLoading} disabled={errorRepeatPassword || errorGeneralPasswordPassedOrNot} variant="primary" icon={SaveIcon}>
            {__('update_label_button')}
          </Button>
        </InlineStack>
        <br />
        <br />
        <br />
        <br />
      </Form>
    </>;
}