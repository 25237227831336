import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils';
import helpers from 'helpers/index';


type TypeTask = {
  task_id?: string,
  project_id?: string,
  task_title?: string,
  task_description?: string,
  task_status?: string,
  createdAt?: string,
  createdBy?: string,
  lastUpdated?: string,
  user?: any;
}

/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as TypeTask[],
  entity: null as TypeTask,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'task';

// Actions

export const getEntities = createAsyncThunk('task/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return await axios.get<any>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk('task/delete_entity',
  async (task_id: string | number) => {
    return await axios.delete<any>(`${apiUrl}/${task_id}`);
  },
  { serializeError: serializeAxiosError }
);


export const createEntity = createAsyncThunk(
  'task/create_entity',
  async (entity: TypeTask) => {
    return await axios.post<TypeTask>(`${apiUrl}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'task/update_entity',
  async (entity: TypeTask) => {
    // patch
    const task_id = String(entity.task_id);
    delete entity.task_id;
    return await axios.patch<TypeTask>(`${apiUrl}/${task_id}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_System_task = createEntitySlice({
  name: 'task',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.totalItems = 0;
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
        state.entities = [];
      })


      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })



      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })


      .addCase(createEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(createEntity.pending, (state, action) => {
        state.updating = true;
        state.updateSuccess = false;
      })



  },
});

export const { clearError, reset } = Reducer_System_task.actions;

// Reducer
export default Reducer_System_task.reducer;
