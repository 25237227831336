import { Badge, DataTable, EmptyState, Link, Text, Page, Toast, Avatar, Loading, Card } from '@shopify/polaris';
import { PlusIcon } from '@shopify/polaris-icons';
import Pagination from 'components/pagination';
import { useAppDispatch, useAppSelector } from 'config/store';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearError, getEntities } from 'store/project.store.reducer';
import SimpleFilter from 'components/simpleFilter';
import dateandtime from 'date-and-time';
import ProjectNew from './project.new';
export default function ProjectList() {
  const entities = useAppSelector(state => state.project.entities);
  const loading = useAppSelector(state => state.project.loading);
  const totalItems = useAppSelector(state => state.project.totalItems);
  const updating = useAppSelector(state => state.project.updating);
  const updateSuccess = useAppSelector(state => state.project.updateSuccess);
  const dispatch = useAppDispatch();
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    dispatch(getEntities(mainQuery));
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Project', '/project' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   *
   * @param post_id
   */
  const shortcutActions = (campaignID: any) => {
    history('/project/view/' + campaignID);
  };
  const emptyData = <EmptyState image={''}>
      <p>{__('no_project_to_show')}</p>
    </EmptyState>;
  function project_status(_status: number) {
    switch (_status) {
      case 0:
        return <Badge>Bản nháp</Badge>;
      case 1:
        return <Badge tone="attention">Đang diễn ra</Badge>;
      case 2:
        return <Badge tone="success">Đã hoàn thành</Badge>;
      case 9:
        return <Badge tone="critical-strong">Hủy dự án</Badge>;
    }
  }
  const avatarListing = (array: any[]) => {
    const maxVisible = 3;
    return array.map((el?: any, index?: number) => {
      if (index < maxVisible) {
        return <Avatar key={index} size="sm" initials={(el?.assignee?.display_name).charAt(0)} source={el?.assignee?.user_avatar} />;
      } else if (index === maxVisible) {
        return <span key={index} className="more-count">
              &nbsp; +{array.length - maxVisible} more
            </span>;
      } else {
        return null;
      }
    }).filter(el => el !== null);
  };
  const renderItem = (entity: any) => {
    let deadline = helpers.subtractDate(entity.project_deadline);
    let deadlineBadge = Number(deadline) <= 0 ? <span>{Math.abs(Number(deadline)) + ' ngày'}</span> : <span>Hết hạn</span>;
    return [<div style={{
      width: '200px'
    }}>
        <Link key={entity.project_id} onClick={() => shortcutActions(entity.project_id)}>
          <Text as="span" variant="bodyMd" fontWeight="bold">
            {entity.project_title}
          </Text>
        </Link>
      </div>, <div className="list_user_loosing_space">{avatarListing(entity?.user_to_project)}</div>, <Link url={'/customer/view/' + entity?.customer_id}>
        <Text as="span">{entity?.customer_data?.display_name || entity?.customer_data?.user_login}</Text>
      </Link>, helpers.formatNumber(entity.project_price, ','), dateandtime.format(new Date(Number(entity.createdAt)), 'DD-MM-YYYY'), deadlineBadge, entity?._count?.task || 0, project_status(entity?.project_status)];
  };
  const [showModal, setShowModal] = useState(false);
  const onCloseModal = useCallback((project_id: string) => {
    setShowModal(false);
  }, []);

  /**
   * Notification
   */
  const toggleToastActive = useCallback(() => {
    dispatch(clearError());
  }, []);
  const toastMarkup = updateSuccess ? <Toast content={'Thành công!'} onDismiss={toggleToastActive} duration={4500} /> : null;
  return <>
      {toastMarkup}
      {loading || updating ? <Loading /> : null}

      <ProjectNew show={showModal} onClose={onCloseModal} />
      <Page fullWidth title="Quản lý dự án" secondaryActions={[{
      content: 'Thêm dự án',
      icon: PlusIcon,
      onAction: () => setShowModal(true)
    }]}>
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
          label: 'Bản nháp',
          value: '0',
          field: 'project_status'
        }, {
          label: 'Đang triển khai',
          value: '1',
          field: 'project_status'
        }, {
          label: 'Đã hoàn thành',
          value: '2',
          field: 'project_status'
        }, {
          label: 'Đã hủy',
          value: '9',
          field: 'project_status'
        }]} sortField={[{
          label: 'Giá trị',
          field: 'project_price'
        }]} />

          {entities && entities.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric', 'numeric', 'numeric', 'text']} headings={['Tên dự án', 'Thành viên', 'Khách hàng', 'Giá trị', 'Ngày bắt đầu', 'Kết thúc', 'Task', 'Tình trạng']} hideScrollIndicator rows={entities.map(renderItem)} /> : emptyData}
        </Card>
        <br />
        {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
      </Page>
    </>;
}