import { Card, Page, DataTable, Text } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'config/store';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import { clearError, getEntities } from 'store/email.incoming.store.reducer';
import SimpleFilter from 'components/simpleFilter';
import { ___ } from 'languages/index';
export default function Webmail_incoming() {
  const entities = useAppSelector(state => state.email_incoming.entities);
  const loading = useAppSelector(state => state.email_incoming.loading);
  const totalItems = useAppSelector(state => state.email_incoming.totalItems);
  const dispatch = useAppDispatch();
  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  useEffect(() => {
    dispatch(clearError());
  }, []);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    dispatch(getEntities(mainQuery));
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Email incoming', '/webmail/incoming' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */

  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    // console.log(_value, '_value');
    // return;
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);
  const renderItem = (item: any) => {
    let {
      id,
      from_name,
      from_email,
      to_email,
      subject,
      createdAt
    } = item;
    return [`${from_name} <${from_email}>`, to_email, helpers.trimContentString(subject, 50), dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm')];
  };
  return <Page fullWidth title="Email đến" subtitle="Email tương tác đến các tài khoản IMAP của bạn" compactTitle>
      <Card padding="0">
        <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
        <DataTable sortable={[false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text']} headings={['Từ', 'Đến', 'Subject', 'Nhận lúc']} rows={entities ? entities?.map(renderItem) : []} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader footerContent={___('Show page {display_page_number} of {total_record_number} results', {
        display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
        total_record_number: <Text as="strong">{totalItems}</Text>
      })} />
      </Card>

      <br />
      {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
    </Page>;
}