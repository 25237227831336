import { Badge, Button, CalloutCard, Card, Link, DataTable, ExceptionList, SkeletonBodyText, Text } from '@shopify/polaris';
import Comments from 'components/CommentsComponent';
import { useAppDispatch, useAppSelector } from 'config/store';
import helpers from 'helpers/index';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import orderStatus from 'config/order.status.json';
import orderPlaceholder from 'media/images/order_placeholder.svg';
import customerEmpty from 'media/images/customer_empty.svg';
import { CheckSmallIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import ModalQuickAddCustomerToCampaign from '../customer.modal.addCustomerToCampaign';
import { useCreateOrder } from 'queries/orders.query';
import { TypedUser } from 'interface/user.model';
import { TypedCustomer } from 'interface/customer.model';
export default function CustomerOtherInformation({
  entity
}: {
  entity: TypedCustomer;
}) {
  const {
    mutateAsync: orderCreateEntity,
    data,
    isPending: loadingOrders
  } = useCreateOrder();

  /**
   * Get all campaign this customer belong to...
   */

  const loadingCustomerCampaign = useAppSelector(state => state.customer_campaign_relationship.loading);
  const history = useNavigate();
  let {
    customer_id
  } = useParams();
  const QuickCreateOrder = useCallback(() => {
    orderCreateEntity({
      user_id: customer_id
    }).then(result => {
      history('/order/view/' + result.order_id);
    }).catch(e => {});
  }, []);

  /**
   * Button quick add customer to a campaign
   */
  const [showQuickAddCustomerToACompaign, setShowQuickAddCustomerToACompaign] = useState<boolean>(false);
  function onCloseModalAddCustomerToCampaign() {
    setShowQuickAddCustomerToACompaign(false);
  }

  /**
   * Chuyển sang danh sách đơn hàng của khách hàng này ...
   * @returns
   */
  function viewAllCustomerOrders() {
    return history('/order', {
      state: {
        customer_id: customer_id
      }
    });
  }
  function getOrderBadge(orderStatus: number): any {
    switch (orderStatus) {
      case 0:
        return 'info';
      case 1:
        return 'complete';
      case 2:
        return 'success';
    }
  }

  /** Convert to status */
  /**
   * Case 0: pending: nháp
   * Case 1: inprocess: hẹn gọi lại
   * Case 4: lost: Lost
   * Case 5: spam: SPAM
   * Case 9: OK
   * @param statusNum
   * @returns
   */
  function getCustomerCampaignStatus(statusNum: number) {
    switch (statusNum) {
      case 0:
        return '-';
      case 1:
        return <Badge progress="partiallyComplete" tone="warning">
            Hẹn
          </Badge>;
      case 4:
        return <Badge progress="incomplete">Lost</Badge>;
      case 5:
        return <Badge progress="incomplete">SPAM</Badge>;
      case 9:
        return <Badge tone="success" progress="complete">
            OK
          </Badge>;
      default:
        return <Badge progress="incomplete" tone="attention">
            Nháp
          </Badge>;
    }
  }
  function getPaymentStatusBadge(status: number) {
    switch (status) {
      case 0:
        return <Badge></Badge>;
      case 1:
        return <Badge tone={'warning'} progress={'partiallyComplete'}>
            T.Toán một phần
          </Badge>;
      case 2:
        return <Badge tone={'success'} progress={'complete'}>
            Đã thanh toán
          </Badge>;
    }
  }
  const LoadingElement = useCallback(() => {
    return <Card>
        <SkeletonBodyText />
      </Card>;
  }, []);
  return <>
      <Text as="h3" variant="headingMd">
        Đơn hàng của khách
      </Text>
      <br />

      <Card padding="0">
        {loadingOrders ? <LoadingElement /> : null}

        {entity?.orders_orders_user_idTouser?.length < 1 ? <CalloutCard title="Khách hàng này chưa mua đơn nào!" illustration={orderPlaceholder} primaryAction={{
        content: 'Lên đơn ngay',
        url: '#',
        onAction: QuickCreateOrder
      }} /> : null}

        {entity?.orders_orders_user_idTouser?.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'text', 'text']} headings={['PNR', 'Tình trạng', 'Thanh toán', 'Giá trị', 'Ngày tạo']} rows={entity?.orders_orders_user_idTouser?.map(orderData => {
        const {
          order_id,
          order_pnr,
          order_status,
          order_total_price,
          payment_status,
          customer_campaign,
          createdAt
        } = orderData;
        return [<Link url={'/order/view/' + order_id} target="_blank">
                  {String(customer_campaign) === String(customer_id) ? <div className="small-icon">
                      <CheckSmallIcon />
                    </div> : ''}
                  {order_pnr}
                </Link>, <Badge tone={getOrderBadge(order_status)}>{orderStatus[order_status]}</Badge>, getPaymentStatusBadge(payment_status), helpers.formatNumber(Number(order_total_price), ','), helpers.subtractDate(createdAt, ' ngày trước')];
      })} hideScrollIndicator hasZebraStripingOnData increasedTableDensity /> : null}
      </Card>
      <br />
      <ExceptionList items={[{
      icon: PlusCircleIcon,
      description: <>
                <Button variant="plain" loading={loadingOrders} onClick={viewAllCustomerOrders}>
                  Xem toàn bộ đơn
                </Button>{' '}
                của khách hàng này hoặc{' '}
                <Button variant="plain" loading={loadingOrders} disabled={loadingOrders} onClick={QuickCreateOrder}>
                  {' '}
                  tạo nhanh đơn hàng
                </Button>{' '}
                cho khách
              </>
    }]} />
      <br />

      <Text as="h3" variant="headingMd">
        Chiến dịch khách có mặt
      </Text>
      <br />
      <Card padding={'0'}>
        {entity?.customer_campaign_relationship_customer_campaign_relationship_user_idTouser?.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'text', 'text']} headings={['Tên chiến dịch', 'Tình trạng', 'Người tạo', 'Ngày tạo']} rows={entity?.customer_campaign_relationship_customer_campaign_relationship_user_idTouser?.map(customerCampaign => {
        return [<div key={customerCampaign.customer_id}>
                  <Link url={'/customer_campaign/view/' + customerCampaign?.customer_campaign?.campaign_id}>
                    {customerCampaign?.customer_campaign?.campaign_name}
                  </Link>
                </div>, <div key={customerCampaign.customer_id}>{getCustomerCampaignStatus(customerCampaign?.customer_campaign_status)}</div>, <div key={customerCampaign.customer_id}>{customerCampaign?.user_customer_campaign_relationship_user_idTouser?.user_login}</div>, <div key={customerCampaign.customer_id}>
                  {customerCampaign?.createdAt ? helpers.subtractDate(Number(customerCampaign?.createdAt), ' ngày trước') : '-'}
                </div>];
      })} hideScrollIndicator hasZebraStripingOnData increasedTableDensity /> : loadingCustomerCampaign ? <LoadingElement /> : <CalloutCard title="Khách hàng chưa có trong một chiến dịch nào!" illustration={customerEmpty} primaryAction={{
        content: 'Thêm khách vào chiến dịch',
        url: '#',
        onAction: () => setShowQuickAddCustomerToACompaign(true)
      }}></CalloutCard>}
      </Card>

      <br />
      <ExceptionList items={[{
      icon: PlusCircleIcon,
      description: <>
                <Button variant="plain" onClick={() => setShowQuickAddCustomerToACompaign(true)}>
                  Thêm nhanh khách hàng
                </Button>
                {' vào một chiến dịch'}
              </>
    }]} />
      <br />

      <Comments objectId={customer_id} commentType="customer_comment" title="Nhận xét" />
      {showQuickAddCustomerToACompaign ? <ModalQuickAddCustomerToCampaign onClose={onCloseModalAddCustomerToCampaign} /> : null}
    </>;
}