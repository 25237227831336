import blacklist_ip from './blacklist_ip.store.reducer';
import blacklist_device_uuid from './blacklist_device_uuid.store.reducer';
import activity from './activity.store.reducer';
import analytics from './analytics.store.reducer';
import user_activity from './user_activity.store.reducer';
import customer_campaign from './customer_campaign.store.reducer';
import customer_campaign_relationship from './customer_campaign_relationship.store.reducer';
import product_to_collection from './product_to_collection.store.reducer';
import comment from './comment.store.reducer';
import transaction_to_project from './transaction_to_project.store.reducer';


import incoming_webhook from './incoming_webhook.store.reducer';
import outgoing_webhook from './outgoing_webhook.store.reducer';

import backup from './backup.store.reducer';
import phonelog from './phonelog.store.reducer';
import sms from './sms.store.reducer';
import project from './project.store.reducer';
import task from './task.store.reducer';
import email_setting from './email_setting.store.reducer';
import email_template from './email_template.store.reducer';
import email_outgoing from './email.outgoing.store.reducer';
import email_incoming from './email.incoming.store.reducer';


const rootReducer = {
  blacklist_ip,
  blacklist_device_uuid,
  activity,
  email_setting,
  email_template,
  email_outgoing,
  email_incoming,
  analytics,
  backup,
  product_to_collection,
  project,
  task,
  user_activity,
  customer_campaign,
  customer_campaign_relationship,
  comment,
  incoming_webhook,
  outgoing_webhook,
  transaction_to_project,
  phonelog,
  sms
};

export default rootReducer;
