import { Card, Badge, Text, IndexTable, Link, Page, useIndexResourceState, Thumbnail, Icon, InlineStack } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DeleteIcon, VariantIcon } from '@shopify/polaris-icons';
import { useGetProducts, useBulkupdateProducts } from 'queries/product.query';
import helpers from '../../helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'components/pagination';
import SkeletonLoading from 'components/skeletonPageLoading2';
import __ from 'languages/index';
import { Helmet } from 'react-helmet-async';
import { TypedProduct } from 'interface/product.model';
import ProductFilter from './filter';
export default function ProductList() {
  const [entities, setEntities] = useState<TypedProduct[] | null>(null);
  const [totalItems, setTotalItems] = useState(0);

  // const dispatch = useAppDispatch();
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 25,
    product_status: '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data: product_entities,
    isLoading: loading
  } = useGetProducts(mainQuery);
  useEffect(() => {
    if (product_entities) {
      let {
        body,
        totalItems
      } = product_entities;
      setTotalItems(totalItems);
      setEntities(body);
    }
  }, [product_entities]);
  const {
    isPending: updating,
    mutateAsync: updateEntities
  } = useBulkupdateProducts();
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (!_value) {
      setMainQuery(initialQuery);
    } else {
      setMainQuery(prevMainQuery => ({
        ...prevMainQuery,
        ..._value
      }));
    }
  }, []);

  /**
   * Lòng và lòng vòng, cuối cùng là để nó không gọi quá nhiều lần ...
   */
  const reduceLoad = useCallback((_mainQuery: any) => {
    let buildURLSearch = helpers.buildEndUrl(_mainQuery);
    if (window.location.search !== buildURLSearch) window.history.replaceState(null, 'Product', '/product' + buildURLSearch);
    getEntities();
  }, [getEntities]);
  const reducerLoading = useMemo(() => helpers.debounce(_value => reduceLoad?.call(this, _value), 900), []);
  useEffect(() => {
    reducerLoading(mainQuery);
  }, [mainQuery]);
  const resourceName = {
    singular: 'product',
    plural: 'products'
  };
  const resourceIDResolver = products => {
    return products.product_id;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(entities ?? [], {
    resourceIDResolver
  });
  const bulkActions = useCallback((_mode: string) => {
    updateEntities({
      product_id: selectedResources.join(','),
      product_status: _mode
    });
  }, [selectedResources]);
  const promotedBulkActions = [{
    content: 'Ngừng bán',
    onAction: () => bulkActions('0'),
    icon: DeleteIcon
  }, {
    content: 'Đang bán',
    onAction: () => bulkActions('1')
  }];
  function badgeStatus(_status) {
    switch (_status) {
      case 0:
        return <Badge>Ngừng bán</Badge>;
      case 1:
        return <Badge tone="success">Đang bán</Badge>;
      default:
        return <Badge>Chưa rõ</Badge>;
    }
  }
  const rowMarkup = entities?.map(({
    product_id,
    product_name,
    product_excerpt,
    product_price,
    product_original_price,
    product_price_range,
    product_type,
    product_status,
    product_sold_quantity,
    product_thumbnail_to_media,
    product_has_variants,
    product_to_brand,
    product_to_category
  }, index) => {
    let priceRange = product_price_range ? product_price_range : helpers.formatNumber(product_price, ',');
    return <IndexTable.Row id={product_id} key={product_id} selected={selectedResources.includes(product_id)} position={index}>
          <IndexTable.Cell>
            <Thumbnail source={helpers.getMediaLink(product_thumbnail_to_media ? product_thumbnail_to_media.media_thumbnail['128x128'] : undefined)} alt={'product thumbnail: ' + product_name} />
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Link url={'/product/edit/' + product_id} removeUnderline>
              <InlineStack align="start" blockAlign="start">
                {product_has_variants === 1 && <span>
                    <Icon source={VariantIcon} />
                  </span>}
                {product_name}
              </InlineStack>
            </Link>
            <Text as="p" tone="subdued" variant="bodySm">
              {product_excerpt ? helpers.trimContentString(product_excerpt, 60) : ''}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {product_to_category ? product_to_category.map(pc => pc.product_category.category_name).join(', ') : '--'}
          </IndexTable.Cell>
          <IndexTable.Cell>{product_to_brand ? product_to_brand?.product_brand?.brand_name : '--'}</IndexTable.Cell>
          <IndexTable.Cell>{badgeStatus(product_status)}</IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" variant="bodyMd" alignment="end">
              {priceRange}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" variant="bodyMd" alignment="end">
              {helpers.formatNumber(product_original_price, ',')}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" variant="bodyMd" alignment="end">
              {product_sold_quantity}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>{product_type}</IndexTable.Cell>
        </IndexTable.Row>;
  });
  return <>
      <Helmet>
        <title>Danh sách sản phẩm</title>
      </Helmet>
      {entities === null ? <SkeletonLoading fullWidth /> : <Page fullWidth title={'Sản phẩm'} primaryAction={{
      content: 'Thêm sản phẩm',
      onAction: () => history('/product/edit/new')
    }}>
          <Card padding="0">
            <ProductFilter onCallback={filterCallback} loading={false} sortField={[{
          label: 'Giá sản phẩm',
          field: 'product_price'
        }, {
          label: 'Đã bán',
          field: 'product_sold_quantity'
        }]} />

            <IndexTable resourceName={resourceName} itemCount={entities?.length || 0} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} bulkActions={[]} onSort={null} loading={updating} promotedBulkActions={promotedBulkActions} sortable={[]} headings={[{
          title: ''
        }, {
          title: 'Sản phẩm'
        }, {
          title: 'Chuyên mục'
        }, {
          title: 'Nhãn hiệu'
        }, {
          title: 'Tình trạng'
        }, {
          title: 'Giá bán',
          alignment: 'end'
        }, {
          title: 'Giá gốc',
          alignment: 'end'
        }, {
          title: 'Đã bán',
          alignment: 'end'
        }, {
          title: 'Loại'
        }]}>
              {rowMarkup}
            </IndexTable>
          </Card>
          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery.limit)} activeCurrentPage={Number(mainQuery.page)} /> : null}
        </Page>}
    </>;
}