import React from 'react';
import { Card, Badge, Text,
// IndexTable,
Link, Page,
// useIndexResourceState,
Thumbnail,
// LegacyCard,
DataTable,
// TableData,
InlineStack, Button } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import { DeleteIcon, EditIcon } from '@shopify/polaris-icons';
import Pagination from 'components/pagination';
// import SkeletonLoading from 'components/skeletonPageLoading';
import __, { ___ } from 'languages/index';
// import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import ProductCategoryModalAddAndEdit from './user_group.edit.modal';
import { useGetUser_groups, useDeleteUser_group, TypedUser_group } from 'queries/user_group.query';
import DeleteConfirmModal from 'components/deleteConfirm';
import SimpleFilter from 'components/simpleFilter';
export default function UserGroup() {
  const [entities, setEntities] = useState<TypedUser_group[]>(null);
  const [totalItems, setTotalItems] = useState(0);
  const {
    mutateAsync: deletePostCategory
  } = useDeleteUser_group();

  // const dispatch = useAppDispatch();
  // const history = useNavigate();

  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data: productCategoryData
  } = useGetUser_groups(mainQuery);
  useEffect(() => {
    if (productCategoryData) {
      let {
        body,
        totalItems
      } = productCategoryData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [productCategoryData]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    setMainQuery(mainQuery);
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'User Group', '/user_group' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [showModal, setShowModal] = useState(false);
  const modalEntity = useRef(null);
  const clickToShowEditModal = useCallback((userGroupData: TypedUser_group) => {
    modalEntity.current = userGroupData;
    setShowModal(true);
  }, []);

  // delete ?
  const currentDeleteID = useRef<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteCallback = useCallback((id: any) => {
    currentDeleteID.current = id;
    setShowDeleteModal(true);
  }, []);
  const deleteProductCategoryQueryCallback = useCallback(async () => {
    try {
      await deletePostCategory(currentDeleteID.current);
    } catch (e) {}
    setShowDeleteModal(false);
  }, [currentDeleteID.current]);
  return <>
      <Helmet>
        <title>Quản lý nhóm người dùng</title>
      </Helmet>

      <DeleteConfirmModal show={showDeleteModal} onClose={async agree_or_not => {
      if (agree_or_not) {
        await deleteProductCategoryQueryCallback();
      } else {
        setShowDeleteModal(false);
      }
    }} title={'Xóa nhóm người dùng'} content="Khi xóa một nhóm người dùng, những tài khoản trong đó không bị ảnh hưởng." />

      <ProductCategoryModalAddAndEdit show={showModal} onClose={(el: any) => {
      modalEntity.current = null;
      setShowModal(false);
    }} entity={modalEntity.current} />

      <Page title="Nhóm người dùng" primaryAction={{
      content: 'Tạo mới nhóm',
      onAction: () => setShowModal(true)
    }}>
        <Card padding={'0'}>
          <SimpleFilter onCallback={filterCallback} loading={false} options={[]} sortField={[{
          label: 'Số thành viên',
          field: 'user_to_group._count'
        }]} />

          <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'numeric', 'text', 'numeric', 'numeric']} headings={['Tên nhóm', 'Mô tả', 'Quản lý nhóm', 'Thành viên', 'Hiển thị', 'Ngày tạo', '-']} rows={entities?.length > 0 ? entities.map((userGroupData, index) => {
          return [<Link removeUnderline url={`/user_group/${userGroupData.user_group_id}`} key={index}>
                        {userGroupData.user_group_thumbnail ? <InlineStack blockAlign="center" gap={'100'}>
                            <Thumbnail size="small" source={helpers.getMediaLink(userGroupData.user_group_thumbnail_to_media ? userGroupData.user_group_thumbnail_to_media.media_thumbnail ? userGroupData.user_group_thumbnail_to_media.media_thumbnail['128x128'] : null : null)} alt={''} />
                            <Text as="span">{userGroupData.user_group_name}</Text>
                          </InlineStack> : <Text as="span">{userGroupData.user_group_name}</Text>}
                      </Link>, <Text as="span">{helpers.trimMiddleString(userGroupData.user_group_description, 30, 10)}</Text>, userGroupData?.user_group_leader ? <Link url={`/customer/view/${userGroupData?.user_group_leader}`}>
                          {userGroupData?.user_group_leader_to_user?.display_name || userGroupData?.user_group_leader_to_user?.user_login}
                        </Link> : '--', helpers.formatNumber(userGroupData?._count?.user_to_group || 0, ','), userGroupData.user_group_status === 1 ? <Badge tone="success">Có</Badge> : <Badge tone="warning">Không</Badge>, dateandtime.format(new Date(Number(userGroupData.createdAt)), 'DD-MM-YYYY'), <InlineStack gap="400">
                        <Button variant="plain" onClick={() => clickToShowEditModal(userGroupData)} icon={EditIcon}></Button>
                        <Button variant="plain" onClick={() => deleteCallback(userGroupData.user_group_id)} icon={DeleteIcon}></Button>
                      </InlineStack>];
        }) : []} footerContent={___('Show {display_record_number} of {total_record_number} results', {
          display_record_number: <Text as="strong">{entities?.length || 0}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
        </Card>
        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}