import { FormLayout, Modal, TextField, Text, Form, Select, InlineGrid, ResourceList, Avatar, ResourceItem, Link, Spinner, BlockStack } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'config/store';
import helpers from 'helpers';
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import { clearError as customerCampaignClearError, createEntity as customerCampaignAddCustomerToList, clearEntity as resetCustomerCampaignData } from 'store/customer_campaign_relationship.store.reducer';
import diachinh from 'config/diachinh.json';
import QuickSearchUser from './quickSearchUser';
import QuickSearchCampaign from './quickSearchCampaign';
import __ from 'languages/index';
import { useCreateCustomer, useGetCustomers } from 'queries/customer.query';

/**
 *   Hàm này chỉ hoạt động ở customer campaign
 */

// eslint-disable-next-line import/no-anonymous-default-export

export default function AddNewCustomerForCustomerCampaign({
  show,
  onClose,
  default_campaign = null
}: {
  show: boolean;
  onClose: Function;
  default_campaign?: string | null;
}) {
  const {
    data: entity,
    mutateAsync: createEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useCreateCustomer();
  const initialQuery = {
    limit: 2,
    query: ''
  };
  const [mainQuery, setMainQuery] = useState<any>(initialQuery);
  const [entities, setEntities] = useState([]);
  const {
    data,
    refetch: getEntities,
    isLoading: loading
  } = useGetCustomers(mainQuery);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
    }
  }, [data]);
  const dispatch = useAppDispatch();
  const createCustomerCampaignRelationshipLoading = useAppSelector(state => state.customer_campaign_relationship.updating);
  const createCustomerCampaignRelationshipUpdateSuccess = useAppSelector(state => state.customer_campaign_relationship.updateSuccess);
  const createCustomerCampaignRelationshipEntity = useAppSelector(state => state.customer_campaign_relationship.entity);
  const [userAssigneeValue, setUserAssigneeValue] = useState(null);
  const [currentCampaignID, setCurrentCampaignID] = useState(default_campaign);
  const [currentCustomerSource, setCurrentCustomerSource] = useState('');
  useEffect(() => {
    if (show) {
      reset();
      dispatch(customerCampaignClearError());
      setAddNewOrAddExistingData(false);
    }
  }, [show]);
  const [customerNumberphone, setCustomerNumberphone] = useState('');
  const onChangeCallback = useMemo(() => helpers.debounce(_value => onChangeText?.call(this, _value), 400), []);
  function onChangeText(_t: string) {
    setMainQuery({
      limit: 2,
      query: _t
    });
    getEntities();
  }
  const handleCustomerPhonenumberChange = useCallback((newValue: string) => {
    setCustomerNumberphone(newValue);
    onChangeCallback(newValue);
  }, []);
  const useFields = {
    display_name: useField<string>({
      value: '',
      validates: [lengthLessThan(50, 'Không được dài hơn 50 ký tự.'), lengthMoreThan(1, 'Tên phải dài hơn 1 ký tự.')]
    }),
    bio: useField<string>({
      value: '',
      validates: [lengthLessThan(250, 'No more than 250 characters.')]
    }),
    user_phonenumber: useField<string>({
      value: '',
      validates: [inputValue => {
        if (inputValue && !helpers.isPhoneNumber(inputValue)) {
          return 'Số điện thoại không hợp lệ';
        }
      }]
    }),
    user_birthday: useField<any>({
      value: '',
      validates: []
    }),
    user_address: useField<string>({
      value: '',
      validates: [lengthLessThan(150, 'No more than 150 characters.')]
    }),
    user_address_city: useField<string>({
      value: '',
      validates: []
    }),
    user_address_district: useField<string>({
      value: '',
      validates: []
    }),
    user_address_ward: useField<string>({
      value: '',
      validates: []
    }),
    user_email: useField<string>({
      value: '',
      validates: [inputValue => {
        if (inputValue) if (!helpers.isEmail(inputValue)) {
          return 'Your email is not valid!';
        }
      }]
    })
  };

  /**
   * selectableCallback
   * @param user_id
   */
  function addCustomerToCampaign(user_id: any): Promise<any> {
    return dispatch(customerCampaignAddCustomerToList({
      campaign_id: currentCampaignID,
      user_id: user_id,
      customer_source: currentCustomerSource,
      assign_for: userAssigneeValue
    }));
  }

  /**
   * Diving like tab
   */

  const [addNewOrAddExistingData, setAddNewOrAddExistingData] = useState(false);
  const showAddNewFieldTab = useCallback(() => {
    setAddNewOrAddExistingData(true);
  }, []);

  /**
   * Save and close or save and moving to detail?
   */
  const [saveAndClose, setSaveAndClose] = useState(true);
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        await createEntity({
          display_name: values.display_name,
          bio: values.bio,
          user_phonenumber: values.user_phonenumber,
          user_email: values.user_email,
          user_address: values.user_address,
          user_address_city: values.user_address_city,
          user_address_district: values.user_address_district,
          user_address_ward: values.user_address_ward,
          user_birthday: values.user_birthday
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * Hard close ...
   */
  const savingCallbackData = useRef([]);
  function modalClose() {
    onClose(savingCallbackData.current);
    dispatch(resetCustomerCampaignData());
    dispatch(customerCampaignClearError());
  }
  /**!SECTION
   * Khi tạo record thành công thì bắt đầu mí đóng modal hoặc reset
   */
  /**
   * Lưu lại dữ liệu vừa mới tạo để callback
   */
  const savingDataTosaveAndCallback = useCallback(createCustomerCampaignRelationshipEntity => {
    let newData = [createCustomerCampaignRelationshipEntity].concat(savingCallbackData.current);
    savingCallbackData.current = newData;
  }, [savingCallbackData]);
  useEffect(() => {
    if (!createCustomerCampaignRelationshipEntity) return;
    if (!createCustomerCampaignRelationshipUpdateSuccess) return;
    setAddNewOrAddExistingData(false);
    setCustomerNumberphone('');
    reset(); // reset toàn bộ form...
    savingDataTosaveAndCallback(createCustomerCampaignRelationshipEntity);
    if (saveAndClose) {
      modalClose();
      return;
    }
  }, [createCustomerCampaignRelationshipEntity, createCustomerCampaignRelationshipUpdateSuccess]);
  const savingCallbackForAsync = useCallback(async (updateSuccess, show) => {
    if (updateSuccess === true && show) {
      if (typeof entity.user_id !== 'undefined') {
        // save to list ...
        await addCustomerToCampaign(entity.user_id);
      }
    }
  }, [entity]);
  useEffect(() => {
    savingCallbackForAsync(updateSuccess, show);
  }, [updateSuccess, show]);
  const customer_address_city_selectType = useFields.user_address_city;
  const customer_address_district_selectType = useFields.user_address_district;
  const customer_address_ward_selectType = useFields.user_address_ward;

  /**
   * Địa chính
   * Chọn tỉnh, thành phố ...
   */
  const [diachinhCity, setDiachinhCity] = useState<string | undefined>('01');
  const [diachinhDistrict, setDiachinhDistrict] = useState<string | undefined>(undefined);
  const [diachinhDistrictList, setDiachinhDistrictList] = useState(undefined);
  const [diachinhWards, setDiachinhWards] = useState<string | undefined>(undefined);
  const [diachinhWardsList, setDiachinhWardsList] = useState(undefined);
  const diachinhCityCallback = useCallback((_value: string) => {
    setDiachinhCity(_value);
    customer_address_city_selectType.onChange(_value);
  }, []);
  const [diachinhCityList, setDiachinhCityList] = useState(null);
  useEffect(() => {
    let citys = [{
      label: 'Chọn Tỉnh/thành phố',
      value: ''
    }];
    for (let _city in diachinh) {
      let city = diachinh[_city];
      // @ts-ignore
      citys.push({
        label: city.name,
        value: city.code
      });
    }
    setDiachinhCityList(citys);
  }, []);

  /**
   * Địa chính
   * Chọn Quận Huyện
   */
  const diachinhDistrictCallback = useCallback((_value: string) => {
    setDiachinhDistrict(_value);
    customer_address_district_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity) {
      return;
    }

    // setDiachinhDistrict(undefined);
    setDiachinhDistrictList(undefined);
    // setDiachinhWards(undefined);
    setDiachinhWardsList(undefined);
    let quanhuyens = [{
      label: 'Chọn Quận/ Huyện',
      value: ''
    }];
    for (let quanhuyen in diachinh[diachinhCity]?.['quan-huyen']) {
      // @ts-ignore
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[quanhuyen];
      // let city = diachinh[_city];
      // @ts-ignore
      quanhuyens.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhDistrictList(quanhuyens);
  }, [diachinhCity]);

  /**
   * Địa chính
   * Chọn xã Phường ...
   */
  const diachinhWardsCallback = useCallback((_value: string) => {
    setDiachinhWards(_value);
    customer_address_ward_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity || !diachinhDistrict) {
      setDiachinhWardsList(undefined);
      return;
    }
    if (typeof diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict] === 'undefined') {
      return;
    }
    let xathitrans = [{
      label: 'Chọn Xã/ Thị trấn',
      value: ''
    }];
    for (let xathitran in diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']) {
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']?.[xathitran];
      // let city = diachinh[_city];
      xathitrans.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhWardsList(xathitrans);
  }, [diachinhDistrict, diachinhCity]);
  function submitMode(_mode: string) {
    if (_mode === 'saveandclose') {
      setSaveAndClose(true);
    } else {
      setSaveAndClose(false);
    }
    submit();
  }
  return <>
      <Modal open={show} onClose={() => modalClose()} title={'Thêm khách hàng vào chiến dịch'} primaryAction={{
      content: 'Lưu & đóng',
      disabled: !dirty,
      loading: updating || createCustomerCampaignRelationshipLoading,
      onAction: () => {
        submitMode('saveandclose');
      }
    }} secondaryActions={[{
      content: 'Đóng',
      onAction: () => modalClose()
    }, {
      content: 'Lưu & Thêm nữa',
      disabled: !dirty,
      loading: createCustomerCampaignRelationshipLoading,
      onAction: () => {
        submitMode('any');
      }
    }]}>
        <Modal.Section>
          <Form onSubmit={submit}>
            <FormLayout>
              <BlockStack gap={'200'}>
                <Text as="p">Chiến dịch *</Text>
                <QuickSearchCampaign current_campaign={currentCampaignID} onClose={customer_campaign_id => setCurrentCampaignID(customer_campaign_id)} />

                <Text as="p">Giao cho *</Text>
                <QuickSearchUser title="" user_role="sale,sale_admin,admin" current_user_id={userAssigneeValue} onClose={({
                user_id
              }: any) => {
                setUserAssigneeValue(user_id);
              }} />

                <Text as="p">Nguồn khách *</Text>
                <TextField label="Nguồn khách" labelHidden requiredIndicator autoComplete="off" value={currentCustomerSource} onChange={(val: string) => setCurrentCustomerSource(val)} />
              </BlockStack>

              <TextField autoFocus suffix={loading && <Spinner size="small" />} label="Tên, số điện thoại hoặc địa chỉ" value={customerNumberphone} maxLength={11} showCharacterCount onChange={handleCustomerPhonenumberChange} autoComplete="off" />

              {entities && entities.length > 0 ? <ResourceList resourceName={{
              singular: 'customer',
              plural: 'customers'
            }} items={entities ?? []} loading={loading} renderItem={item => {
              const {
                user_id,
                display_name,
                user_avatar,
                user_address,
                user_phonenumber
              } = item;
              const media = <Avatar customer size="md" name={display_name} source={user_avatar} />;
              return <ResourceItem id={user_id} url={null} onClick={async () => {
                await addCustomerToCampaign(user_id);
              }} media={media}>
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {display_name}
                        </Text>
                        <div>
                          {user_phonenumber ? '0' + user_phonenumber : ''}
                          {user_address ? ' / ' + user_address : ''}
                        </div>
                      </ResourceItem>;
            }} /> : <>{customerNumberphone ? <Link onClick={showAddNewFieldTab}>Chưa có dữ liệu khách hàng, thêm mới?</Link> : null}</>}

              <div style={{
              display: addNewOrAddExistingData ? 'block' : 'none'
            }}>
                <TextField autoComplete="off" requiredIndicator label="Tên khách hàng" {...fields.display_name} />

                <br />

                <TextField label="Email" autoComplete="off" {...fields.user_email} />

                <br />
                <TextField label="Số nhà, đường, thôn xóm" autoComplete="off" {...fields.user_address} />

                <br />
                <InlineGrid gap="400" columns={3}>
                  <Select key={'ahjfkdgf'} label="Tỉnh/Thành phố" options={diachinhCityList} value={diachinhCity} onChange={diachinhCityCallback} />
                  <Select key={'ahjfdsfsgkdgf'} label="Quận/ huyện" options={diachinhDistrictList} value={diachinhDistrict} onChange={diachinhDistrictCallback} />
                  <Select key={'ahjfksdgsdgsw4dgf'} label="Xã/Thị trấn" options={diachinhWardsList} value={diachinhWards} onChange={diachinhWardsCallback} />
                </InlineGrid>
                <br />

                <TextField label="Giới thiệu bản thân" autoComplete="off" multiline={2} {...fields.bio} />
              </div>
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>
    </>;
}