import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "../helpers";
import queryClient, { IQueryParams } from './index';
import { TypedUser } from "interface/user.model";
import { Typedorder_fulfillment } from "./order_fulfillment.query";
import { TypedStock } from "./stock.query";


// export enum TypedOrderStatus {

// }

type TypedBatchUpdate = {
    "mode": string, // 
    "mode_value": string,
    "orders": string[]
}

/**
*   Interface/model file auto generate for Comment
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedOrder {
    order_id?: any;
    order_pnr?: string;
    user_id?: string;
    order_total_price?: number;
    order_total_fee?: number;
    order_total_paid?: number;
    order_total_mustpay?: number;
    product_id?: string;
    order_status?: number;
    payment_type?: string;
    payment_status?: number;
    order_note?: string;
    customer_campaign?: bigint;
    customer_campaign_relationship_id?: any;
    order_print_packing_slip?: number;
    order_delivery_type?: number;
    order_has_physical_product?: number; /** để xem là đơn kỹ thuật số hay đơn vật lý ... */
    order_checked?: number;
    createdBy?: string;
    createdAt?: any;
    updatedAt?: any;
    user?: any;
    customer_data?: TypedUser;
    order_fulfillment?: Typedorder_fulfillment;
    stock_id?: string; // for update only, mean nothing here ...

    order_to_stock?: {
        stock?: TypedStock
    };

    order_to_voucher?: {
        order_id?: string,
        voucher_id?: string,
        voucher_name?: string,
        voucher_code?: string,
        voucher_value?: number,
        voucher_value_unit?: string,
        voucher_value_max?: number,
        voucher_category?: string,
        voucher_after_calculator?: number,
    }[]

    pagination?: {
        next?: string | null,
        prev?: string | null
    }
}

type IQuery = TypedOrder & IQueryParams;


/*
* List of all orders 
*/
export function useGetOrders(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["orders/fetch_many"],
        queryFn: () => axios.get<TypedOrder[]>(`/orders${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}

export function useGetOrderSidebar(order_id: string) {
    return useQuery({
        queryKey: ["orders/fetch_many"],
        queryFn: () => axios.get<TypedOrder[]>(`/orders/quick_suft/${order_id}`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}

/*
* Get one orders
*/
export function useGetOrderDetail(order_id: string) {
    return useQuery({
        queryKey: ["orders/fetch_entity"],
        queryFn: () => axios.get<TypedOrder>(`/orders/${order_id}`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}
/*
* Get one orders
*/
export function useMutateGetOrderDetail() {
    return useMutation({
        mutationKey: ["orders/fetch_entity"],
        mutationFn: (order_id: string) => axios.get<TypedOrder>(`/orders/${order_id}`).then(response => response.data)
    });
}

/** Create order  */
export function useCreateOrder() {
    return useMutation({
        mutationKey: ["orders/create_entity"],
        mutationFn: (entity: TypedOrder) => axios.post<TypedOrder>(`/orders`, helpers.cleanEntity(entity)).then(response => response.data)
    });
}

/** Update order */
export function useUpdateOrder() {
    return useMutation({
        mutationKey: ["orders/update_entity"],
        mutationFn: ({ order_id, ...rest }: TypedOrder) => axios.put<TypedOrder>(`/orders/${order_id}`, helpers.cleanEntity(rest)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_many'] });
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_entity'] });
        }
    });
}


/** Update order status - tách riêng để trị ... */
export function useUpdateOrderStatus() {
    return useMutation({
        mutationKey: ["orders/update_entity"],
        mutationFn: (entity: { order_id: string | bigint, order_status: string | number }) => axios.put<TypedOrder>(`/orders/order_status`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_many'] });
        }
    });
}

/** Đối soát đơn hàng - tách riêng để trị ... */
export function useOrderChecked() {
    return useMutation({
        mutationKey: ["orders/order_checked"],
        mutationFn: (order_id: string | bigint) => axios.post<TypedOrder>(`/orders/order_checked`, { order_id: order_id }).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_many'] });
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_entity'] });
        }
    });
}




/** Bulk Update order */
export function useDeleteOrder() {
    return useMutation({
        mutationKey: ["orders/delete_entity"],
        mutationFn: (order_id: string) => axios.delete<TypedOrder>(`/orders/${order_id}`).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_many'] });
        }

    });
}


/** Bulk/Batch Update order */
export function useBulkUpdateOrder() {
    return useMutation({
        mutationKey: ["orders/batch_update_entity"],
        mutationFn: (entity: TypedBatchUpdate) => axios.patch<TypedBatchUpdate>(`/orders/bulkUpdateEntities`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_many'] });
        }

    });
}

/** Bulk/Batch Update order */
export function useBulkDownloadOrder() {
    return useMutation({
        mutationKey: ["orders/batch_download"],
        mutationFn: (order_id: string[]) => axios.post<any>(`/orders/batch_download`, helpers.cleanEntity(order_id)).then(response => response.data),
    });
}



/** Order Assign */
export function useGetAssignList(order_id: string) {
    // return useMutation({
    //     mutationKey: ["order/assign/fetch_entity_list"],
    //     mutationFn: (order_id: string) => axios.get<any>(`/orders/assign/${order_id}`).then(response => response.data),
    // });

    return useQuery({
        queryKey: ["order/assign/fetch_entity_list"],
        queryFn: () => axios.get<any>(`/orders/assign/${order_id}`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });

}


/** Order Add Assign */
export function useCreateAssign() {
    return useMutation({
        mutationKey: ["order/assign/create_entity"],
        mutationFn: (entity: { user_id: any; order_id: any }) => axios.post<any>(`/orders/assign/`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order/assign/fetch_entity_list'] });
        }
    });
}


/** Order Add Assign */
export function useDeleteAssign() {
    return useMutation({
        mutationKey: ["order/assign/delete_entity"],
        mutationFn: (assign_id: string) => axios.delete<any>(`/orders/assign/${assign_id}`).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order/assign/fetch_entity_list'] });
        }
    });
}