import { useCallback, useEffect, useState } from 'react';
import { Button, FormLayout, Page, Text, TextField, Toast, ContextualSaveBar, Checkbox, InlineGrid, BlockStack, Select, Grid, Card, DescriptionList, Divider } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import helpers from '../../helpers';
import QuickSearchEmailTemplate from 'entities/webmail/webmail_search_email_template';
import QuickUploadImage from 'components/oneclick-upload-image';
import { USER_CAPACITY_LIST } from 'constant';
import QuickSearchEmailSetting from 'entities/email_setting/quick_search_email_setting';
import SkeletonLoading from 'components/skeletonPageLoading';
import { useClearAllSession, useGetSettings, useSendTestEmail, useUpdateSetting } from 'queries/setting.query';
import __ from 'languages/index';
import { useRegenerateThumbnailMedia } from 'queries/media.query';
import { Helmet } from 'react-helmet-async';
import { useNotification } from 'NotificationContext';
export default function Settings() {
  const {
    addNotification
  } = useNotification();
  const {
    data: entities,
    isLoading: loading
  } = useGetSettings({
    fields: `setting_name,setting_value`,
    setting_name: `must_add_referrer,must_validated_account,file_size_upload,admin_email,welcome_new_user,default_new_user_role,email_default_template,email_default_setting,site_logo,site_name,site_slogan,site_main_address_1,site_main_address_2,site_main_address_district,site_main_address_city`
  });
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const {
    mutate: regenerateThumbnail
  } = useRegenerateThumbnailMedia();
  let allRoles = [];
  for (let roles of USER_CAPACITY_LIST) {
    allRoles.push({
      label: __(`user_role_` + roles),
      value: roles
    });
  }
  const useFields = {
    file_size_upload: useField<string>({
      value: entities?.file_size_upload ?? '',
      validates: []
    }),
    admin_email: useField<string>({
      value: entities?.admin_email ?? '',
      validates: []
    }),
    welcome_new_user: useField<number>({
      value: Number(entities?.welcome_new_user ?? 1),
      validates: []
    }),
    must_validated_account: useField<number>({
      value: Number(entities?.must_validated_account ?? 0),
      validates: []
    }),
    must_add_referrer: useField<number>({
      value: Number(entities?.must_add_referrer ?? 0),
      validates: []
    }),
    default_new_user_role: useField<string>({
      value: entities?.default_new_user_role ?? 'user',
      validates: []
    }),
    email_default_template: useField<string>({
      value: entities?.email_default_template ?? '',
      validates: []
    }),
    email_default_setting: useField<string>({
      value: entities?.email_default_setting ?? '',
      validates: []
    }),
    site_logo: useField<string>({
      value: entities?.site_logo ?? '',
      validates: []
    }),
    site_name: useField<string>({
      value: entities?.site_name ?? '',
      validates: []
    }),
    site_slogan: useField<string>({
      value: entities?.site_slogan ?? '',
      validates: []
    }),
    site_main_address_1: useField<string>({
      value: entities?.site_main_address_1 ?? '',
      validates: []
    }),
    site_main_address_2: useField<string>({
      value: entities?.site_main_address_2 ?? '',
      validates: []
    }),
    site_main_address_district: useField<string>({
      value: entities?.site_main_address_district ?? '',
      validates: []
    }),
    site_main_address_city: useField<string>({
      value: entities?.site_main_address_city ?? '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          must_validated_account: values.must_validated_account,
          file_size_upload: String(values.file_size_upload || 0).trim(),
          admin_email: values.admin_email,
          default_new_user_role: values.default_new_user_role,
          welcome_new_user: values.welcome_new_user,
          email_default_template: values.email_default_template,
          email_default_setting: values.email_default_setting,
          site_logo: values.site_logo,
          site_name: values.site_name,
          site_slogan: values.site_slogan,
          site_main_address_1: values.site_main_address_1,
          site_main_address_2: values.site_main_address_2,
          site_main_address_district: values.site_main_address_district,
          site_main_address_city: values.site_main_address_city,
          must_add_referrer: values.must_add_referrer
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const {
    mutateAsync: clearAllSession
  } = useClearAllSession();
  const {
    mutateAsync: sendTestEmail
  } = useSendTestEmail();

  /**
   * Notification
   */

  useEffect(() => {
    if (error) {
      addNotification('error', error.message);
    }
  }, [error]);
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('updated_successfully'));
  }, [updateSuccess]);
  const [sendingEmailTesting, setSendingEmailTesting] = useState(false);
  const SendTestEmail = useCallback(async () => {
    setSendingEmailTesting(true);
    submit();
    try {
      await helpers.sleep(1000);
      await sendTestEmail();
    } catch (e) {}
    setSendingEmailTesting(false);
  }, []);
  const [uploadError, setUploadError] = useState(null);
  const [loadingRegenerateThumbnail, setLoadingRegenerateThumbnail] = useState(false);
  const reGenerateThumbnailCallback = useCallback(async () => {
    setLoadingRegenerateThumbnail(true);
    regenerateThumbnail();
    await helpers.sleep(3000);
    setLoadingRegenerateThumbnail(false);
  }, []);
  return <>
      <Helmet>
        <title>Cài đặt chung</title>
      </Helmet>
      {loading ? <SkeletonLoading /> : <Page title="Cài đặt chung">
          <DescriptionList items={[{
        term: 'Thông tin chung',
        description: <Card>
                    <BlockStack>
                      <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
                        <div className="d1">
                          <QuickUploadImage onSuccess={e => {
                  useFields.site_logo.onChange(e.media_url);
                }} onError={e => setUploadError(e)} title="Click để tải logo lên" placeholder={helpers.getMediaLink(useFields.site_logo.value)} />
                          <Text as="p" tone="critical">
                            {uploadError}
                          </Text>
                        </div>
                        <div className="d2">
                          <TextField label="Tên công ty" autoComplete="off" {...fields.site_name} />

                          <TextField label="Slogan" autoComplete="off" {...fields.site_slogan} />
                        </div>
                      </InlineGrid>

                      <div className="site_main_address">
                        <BlockStack gap={'200'}>
                          <Text as="h3" variant="headingMd">
                            Địa chỉ doanh nghiệp
                          </Text>

                          <TextField label="Dòng địa chỉ số 1" autoComplete="off" {...fields.site_main_address_1} />
                          <TextField label="Dòng địa chỉ số 2" autoComplete="off" {...fields.site_main_address_2} />
                          <FormLayout>
                            <FormLayout.Group condensed>
                              <TextField label="Quận/ Huyện" autoComplete="off" {...fields.site_main_address_district} />
                              <TextField label="Tỉnh/thành phố" autoComplete="off" {...fields.site_main_address_city} />
                            </FormLayout.Group>
                          </FormLayout>

                          <Text as="p">Những thông tin này sẽ được ghi vào trong Email và một số template khác ví dụ như invoice ...</Text>
                        </BlockStack>
                      </div>
                    </BlockStack>
                  </Card>
      }, {
        term: 'Thông báo từ hệ thống',
        description: <Card>
                    <Grid>
                      <Grid.Cell columnSpan={{
              xs: 6,
              sm: 3,
              md: 3,
              lg: 6,
              xl: 6
            }}>
                        <QuickSearchEmailTemplate title="Chọn mẫu email mặc định" current_setting={entities?.email_default_template} onClose={email_template_id => useFields.email_default_template.onChange(email_template_id)} />
                      </Grid.Cell>

                      <Grid.Cell columnSpan={{
              xs: 6,
              sm: 3,
              md: 3,
              lg: 6,
              xl: 6
            }}>
                        <QuickSearchEmailSetting title="Chọn tài khoản email mặc định" current_setting={entities?.email_default_setting} onClose={email_setting_id => useFields.email_default_setting.onChange(email_setting_id)} />
                      </Grid.Cell>
                    </Grid>
                    <br />
                    <TextField label="Email của Admin" placeholder="admin@you.com" helpText={`Là email nhận các thông báo tự động từ hệ thống. Email có thể chứa các thông tin quan trọng.`} autoComplete="off" connectedRight={<Button loading={sendingEmailTesting} onClick={() => SendTestEmail()} size="large" variant="primary">
                          Gửi thử
                        </Button>} {...fields.admin_email} />
                  </Card>
      }, {
        term: 'Đăng ký mới',
        description: <Card>
                    <Select label="Vai trò mặc định khi đăng ký mới" options={allRoles} helpText="Bạn cần chọn một vai trò ở mức thấp, ví dụ như: khách hàng (customer) hoặc người dùng(user), mặc định là User" onChange={v => fields.default_new_user_role.onChange(v)} value={String(fields.default_new_user_role.value)} />
                    <br />

                    <Checkbox label="Gửi email chào mừng cho khách hàng khi đăng ký mới nếu họ đăng ký bằng Email" checked={fields.welcome_new_user.value === 1} onChange={e => fields.welcome_new_user.onChange(Number(e))} />

                    <Checkbox label="Bắt buộc xác minh tài khoản khi đăng ký" helpText="Khi đăng ký mới tài khoản bằng hình thức nào, đều nên xác minh, tuy nhiên tùy vào chiến dịch của bạn" checked={fields.must_validated_account.value === 1} onChange={e => fields.must_validated_account.onChange(Number(e))} />

                    <Checkbox label="Bắt buộc thêm mã giới thiệu khi đăng ký" helpText="Khi đăng ký mới tài khoản bằng hình thức nào, đều sẽ yêu cầu thêm mã giới thiệu." checked={fields.must_add_referrer.value === 1} onChange={e => fields.must_add_referrer.onChange(Number(e))} />
                  </Card>
      }, {
        term: 'Cài đặt nâng cao',
        description: <Card>
                    <BlockStack gap="400">
                      <Text as="h3">Upload</Text>
                      <TextField label="Maximum File size in upload form. (in MB)" autoComplete="off" type="number" disabled={loading} {...fields.file_size_upload} />

                      <Text as="p" tone="subdued">
                        Default is 20Mb, if you want to remove this limit, change it to 0
                      </Text>

                      <Divider />

                      <Text as="h3">Dọn dẹp phiên làm việc</Text>
                      <Button tone="critical" loading={false} onClick={clearAllSession}>
                        Clear all session
                      </Button>

                      <Text tone="subdued" as="p">
                        Khi dọn dẹp phiên làm việc, mọi phiên làm việc hết hạn trong cơ sở dữ liệu sẽ được xóa bỏ một cách an toàn.
                      </Text>

                      <Divider />

                      <Text as="h3">Tạo lại toàn bộ thumbnail trong thư viện media</Text>
                      <Button tone="critical" loading={loadingRegenerateThumbnail} onClick={reGenerateThumbnailCallback}>
                        ReGenerate Thumbnail
                      </Button>
                      <Text tone="critical" as="p">
                        * Chú ý: hành động này tốn tài nguyên CPU của máy chủ, cân nhắc sử dụng khi cần thiết.
                      </Text>
                    </BlockStack>
                  </Card>
      }]} />

          <Card padding={'0'}></Card>

          {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
        onAction: () => submit(),
        loading: updating
      }} discardAction={{
        onAction: () => resetForm()
      }} />}
        </Page>}
      <br />
      <br />
    </>;
}