import { Form, InlineGrid, Modal, TextField, Text, Checkbox, BlockStack, Divider, Button, Select, Box, ChoiceList } from '@shopify/polaris';
import { lengthLessThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { TypedProductVariantCommission, TypedProductVariantCommissionAdvance, useGetProductVariant, useUpdateProductVariant } from 'queries/product_variant.query';
import React, { useState, useEffect, useCallback } from 'react';
import { PlusIcon, DeleteIcon } from '@shopify/polaris-icons';
import { USER_CAPACITY_LIST } from 'constant';
import { useNotification } from 'NotificationContext';
import Capacity from 'components/Capacity';
export default function ProductVariantModalEdit({
  show = false,
  onClose = null,
  variant_id = null
}: {
  show: boolean;
  onClose: () => void;
  variant_id: string;
}) {
  const {
    addNotification
  } = useNotification();
  const [open, setOpen] = useState(false);
  let allRoles = [];
  for (let roles of USER_CAPACITY_LIST) {
    allRoles.push({
      label: __(`user_role_` + roles),
      value: roles
    });
  }
  const {
    mutateAsync: updateEntity,
    isSuccess: updateSuccess,
    isPending
  } = useUpdateProductVariant();
  const {
    data: entity,
    mutateAsync: getEntity
  } = useGetProductVariant();
  useEffect(() => {
    if (show) {
      if (variant_id) getEntity(variant_id);
      setOpen(true);
    }
  }, [show, variant_id]);
  const onCloseCallback = useCallback(() => {
    onClose();
    setOpen(false);
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', __('update_successfully'));
      onCloseCallback();
    }
  }, [updateSuccess]);
  useEffect(() => {
    reset();
  }, []);
  const useFields = {
    variant_thumbnail: useField<string>({
      value: entity?.variant_thumbnail_to_media ? entity.variant_thumbnail_to_media?.media_id : undefined,
      validates: []
    }),
    variant_thumbnail_cache: useField<string>({
      value: entity?.variant_thumbnail_to_media ? entity.variant_thumbnail_to_media?.media_thumbnail ? entity.variant_thumbnail_to_media?.media_thumbnail['256x169'] : '' : '',
      validates: []
    }),
    variant_name: useField<string>({
      value: entity?.variant_name || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(150, 'Không được dài hơn 50 ký tự.')]
    }),
    variant_price: useField<string>({
      value: String(entity?.variant_price || 1),
      validates: [inputValue => {
        if (inputValue && helpers.parseNumeric(inputValue) < 1) {
          return 'variant_price must be a number!';
        }
      }]
    }),
    variant_original_price: useField<string>({
      value: String(entity?.variant_original_price ?? '0'),
      validates: [inputValue => {
        if (inputValue && helpers.parseNumeric(inputValue) < 0) {
          return 'variant_original_price must be a number!';
        }
      }]
    }),
    variant_excerpt: useField<string>({
      value: entity?.variant_excerpt || '',
      validates: [lengthLessThan(250, 'Không được dài hơn 250 ký tự.')]
    }),
    variant_sku: useField<string>({
      value: entity?.variant_sku,
      validates: [lengthLessThan(50, 'Không được dài hơn 50 ký tự.')]
    }),
    variant_barcode: useField<string>({
      value: entity?.variant_barcode,
      validates: [lengthLessThan(48, 'Không được dài hơn 48 ký tự.')]
    }),
    variant_status: useField<number>({
      value: entity?.variant_status ?? 0,
      validates: []
    }),
    variant_has_commission: useField<string>({
      value: String(entity?.variant_has_commission ?? -1),
      validates: []
    }),
    product_variant_commission: useField<TypedProductVariantCommission[]>({
      value: helpers.isEmpty(entity?.product_variant_commission || []) ? [{
        commission_level: 1,
        commission_value: 0,
        commission_method: 'percent'
      }] : entity?.product_variant_commission,
      validates: []
    }),
    product_variant_commission_advance: useField<TypedProductVariantCommissionAdvance[]>({
      value: entity?.product_variant_commission_advance || [],
      validates: []
    }),
    variant_has_rebate: useField<string>({
      value: String(entity?.variant_has_rebate ?? -1),
      validates: []
    }),
    product_variant_rebate: useField<{
      rebate_value: number;
      rebate_method: string;
      user_role: string;
    }[]>({
      value: helpers.isEmpty(entity?.product_variant_rebate || []) ? [{
        rebate_value: 1,
        rebate_method: 'percent',
        user_role: 'visitor'
      }] : entity?.product_variant_rebate?.map(({
        rebate_value,
        rebate_method,
        user_role
      }) => {
        return {
          rebate_value,
          rebate_method,
          user_role
        };
      }),
      validates: []
    }),
    variant_has_tiered_rebate: useField<string>({
      value: String(entity?.variant_has_tiered_rebate ?? -1),
      validates: []
    }),
    product_variant_tiered_rebate: useField<{
      rebate_value: number;
      rebate_method: string;
      user_role: string;
    }[]>({
      value: helpers.isEmpty(entity?.product_variant_tiered_rebate || []) ? [{
        rebate_value: 0,
        rebate_method: 'percent',
        user_role: 'agency'
      }, {
        rebate_value: 0,
        rebate_method: 'percent',
        user_role: 'collaborator'
      }] : entity?.product_variant_tiered_rebate?.map(({
        rebate_value,
        rebate_method,
        user_role
      }) => {
        return {
          rebate_value,
          rebate_method,
          user_role
        };
      }),
      validates: []
    })
  };
  const {
    product_variant_commission,
    product_variant_rebate,
    product_variant_tiered_rebate,
    product_variant_commission_advance
  } = useFields;
  const addNewRuleToProductCommissionAdvanceCallback = useCallback((commission_parent_index: number) => {
    let currentValue = [...useFields.product_variant_commission_advance.value, ...[]];
    currentValue.push({
      commission_parent_index: commission_parent_index,
      user_role: 'customer',
      commission_value: 1
    });
    useFields.product_variant_commission_advance.onChange(currentValue);
  }, [useFields.product_variant_commission_advance]);
  const deleteRuleToProductCommissionAdvanceCallback = useCallback((commission_parent_index: number, index_id: number) => {
    let currentValue = [...useFields.product_variant_commission_advance.value, ...[]];
    currentValue = currentValue.filter(e => e.commission_parent_index === commission_parent_index);
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_variant_commission_advance.onChange(currentValue);
  }, [useFields.product_variant_commission_advance]);
  const addNewRuleToProductCommissionCallback = useCallback(() => {
    let currentValue = [...useFields.product_variant_commission.value, ...[]];
    if (currentValue.length > 2) return;
    currentValue.push({
      commission_level: currentValue.length + 1,
      commission_method: 'percent',
      commission_value: 1
    });
    useFields.product_variant_commission.onChange(currentValue);
  }, [useFields.product_variant_commission]);
  const deleteRuleToProductCommissionCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_variant_commission.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_variant_commission.onChange(currentValue);
  }, [useFields.product_variant_commission]);
  const addNewRuleToProductRebateCallback = useCallback(() => {
    let currentValue = [...useFields.product_variant_rebate.value, ...[]];
    currentValue.push({
      rebate_value: 1,
      rebate_method: 'percent',
      user_role: 'visitor'
    });
    useFields.product_variant_rebate.onChange(currentValue);
  }, [useFields.product_variant_rebate]);
  const deleteRuleToProductRebateCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_variant_rebate.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_variant_rebate.onChange(currentValue);
  }, [useFields.product_variant_rebate]);
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          variant_id: entity?.variant_id,
          variant_name: values.variant_name,
          variant_excerpt: values.variant_excerpt,
          variant_thumbnail: values.variant_thumbnail,
          variant_price: helpers.parseNumeric(values.variant_price),
          variant_original_price: helpers.parseNumeric(values.variant_original_price),
          variant_barcode: values.variant_barcode,
          variant_sku: values.variant_sku,
          variant_status: values.variant_status,
          variant_has_commission: Number(values.variant_has_commission),
          variant_has_rebate: Number(values.variant_has_rebate),
          variant_has_tiered_rebate: Number(values.variant_has_tiered_rebate),
          commission: values.product_variant_commission,
          commission_advance: values.product_variant_commission_advance,
          rebate: values.product_variant_rebate,
          tiered_rebate: values.product_variant_tiered_rebate
        });
        reset();
      } catch (e) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /** check giá của hai cái trường giá và báo lỗi cho khách  */
  useEffect(() => {
    let price = helpers.parseNumeric(useFields.variant_price.value);
    let original_price = helpers.parseNumeric(useFields.variant_original_price.value);
    if (original_price > 0 && original_price <= price) {
      useFields.variant_original_price.setError('Giá gốc phải là số cao hơn giá bán.');
    }
  }, [useFields.variant_price.value, useFields.variant_original_price.value]);
  const [uploadError, setUploadError] = useState(null);
  return <Modal activator={null} open={open} onClose={() => onCloseCallback()} title={'Cập nhật biến thể sản phẩm'} primaryAction={{
    content: __('update_label_button'),
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: () => onCloseCallback()
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <BlockStack gap="200">
            <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
              <div className="d1">
                <QuickUploadImage height={160} width={160} onSuccess={e => {
                useFields.variant_thumbnail.onChange(e.media_id);
                useFields.variant_thumbnail_cache.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={helpers.getMediaLink(useFields.variant_thumbnail_cache.value, 'https://placehold.co/256x256')} />
                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </div>
              <BlockStack gap="400">
                <TextField label="Tên biến thể" autoComplete="off" {...fields.variant_name} />
                <TextField label="Giá bán" autoComplete="off" value={helpers.formatNumberWithCommas(useFields.variant_price.value)} onChange={e => useFields.variant_price.onChange(e)} error={useFields.variant_price.error} suffix={'VND'} />
                <TextField label="Giá gốc" autoComplete="off" value={helpers.formatNumberWithCommas(useFields.variant_original_price.value)} onChange={e => useFields.variant_original_price.onChange(e)} error={useFields.variant_original_price.error} suffix={'VND'} />
              </BlockStack>
            </InlineGrid>

            <br />
            <Capacity current_user_can={'admin'}>
              <BlockStack gap="400" id="product_price_extra_setting">
                <ChoiceList title="Hoa hồng (commission)" choices={[{
                label: 'Tuân theo chính sách chung',
                value: '-1'
              }, {
                label: 'Không có hoa hồng',
                value: '0'
              }, {
                label: 'Tùy chỉnh',
                value: '1'
              }]} selected={[useFields.variant_has_commission.value]} onChange={a => useFields.variant_has_commission.onChange(a[0])} />

                {useFields.variant_has_commission.value === '1' && <BlockStack gap="400">
                    {product_variant_commission.value.map((commission, index) => {
                  return <div key={'index_product_commission_' + index}>
                          <TextField autoComplete="off" label={`Hoa hồng Level ${index + 1}`} labelAction={{
                      content: __('commission_method_label_' + commission.commission_method),
                      onAction: () => {
                        product_variant_commission.onChange(product_variant_commission.value.map((item, i) => i === index ? {
                          ...item,
                          commission_method: commission.commission_method === 'percent' ? 'fixed_amount' : 'percent'
                        } : item));
                      }
                    }} value={helpers.formatNumberWithCommas(commission.commission_value.toString())} onChange={value => product_variant_commission.onChange(product_variant_commission.value.map((item, i) => i === index ? {
                      ...item,
                      commission_value: helpers.parseNumeric(value)
                    } : item))} suffix={commission.commission_method === 'percent' ? '%' : 'fixed'} connectedLeft={<Button onClick={() => deleteRuleToProductCommissionCallback(index)} variant="plain" icon={DeleteIcon}></Button>} />

                          <Box paddingInlineStart={'600'} paddingBlock={'200'}>
                            <BlockStack gap={'400'}>
                              {useFields.product_variant_commission_advance.value.map((commission_advance, index2) => {
                          if (commission_advance.commission_parent_index !== index) return undefined;
                          return <TextField size="slim" label={`Nếu người nhận là *** thì nhận thêm (${helpers.formatNumberWithCommas(commission.commission_value.toString())} + x) giá trị`} connectedLeft={<Select label="" labelHidden options={allRoles} onChange={value => product_variant_commission_advance.onChange(product_variant_commission_advance.value.map((item, i) => i === index2 ? {
                            ...item,
                            user_role: value
                          } : item))} value={commission_advance.user_role.toString()} />} labelAction={{
                            content: 'Xoá',
                            onAction: () => deleteRuleToProductCommissionAdvanceCallback(index, index2)
                          }} autoComplete="off" value={helpers.formatNumberWithCommas(commission_advance.commission_value.toString())} onChange={value => product_variant_commission_advance.onChange(product_variant_commission_advance.value.map((item, i) => i === index2 ? {
                            ...item,
                            commission_value: helpers.parseNumeric(value)
                          } : item))} placeholder="Giá trị" suffix={commission.commission_method === 'percent' ? '%' : 'fixed'} />;
                        })}

                              <div>
                                <Button variant="plain" tone="success" onClick={() => addNewRuleToProductCommissionAdvanceCallback(index)} icon={PlusIcon}>
                                  Thêm điều kiện nâng cao
                                </Button>
                              </div>
                            </BlockStack>
                          </Box>
                        </div>;
                })}
                    <Button icon={PlusIcon} onClick={() => addNewRuleToProductCommissionCallback()} variant="primary">
                      Thêm quy tắc
                    </Button>
                    <Text as="p" tone="subdued">
                      Hoa hồng trong chương trình affiliate được thanh toán SAU KHI đơn hàng hoàn thành đối soát. ĐÂY KHÔNG PHẢI LÀ CHIẾT KHẤU.
                    </Text>
                  </BlockStack>}

                <Divider />
                <ChoiceList title="Chiết khấu (rebate)" choices={[{
                label: 'Tuân theo chính sách chung',
                value: '-1'
              }, {
                label: 'Không có chiết khấu',
                value: '0'
              }, {
                label: 'Tùy chỉnh',
                value: '1'
              }]} selected={[useFields.variant_has_rebate.value]} onChange={a => useFields.variant_has_rebate.onChange(a[0])} />
                {useFields.variant_has_rebate.value === '1' && <BlockStack gap="400">
                    {product_variant_rebate.value.map((rebate, index) => {
                  return <InlineGrid columns={{
                    xs: 1,
                    md: 2
                  }} gap="400" key={'index_product_rebate_' + index}>
                          <TextField autoComplete="off" label={`Giá trị chiết khấu ${index + 1}`} labelAction={{
                      content: __('commission_method_label_' + rebate.rebate_method),
                      onAction: () => {
                        product_variant_rebate.onChange(product_variant_rebate.value.map((item, i) => i === index ? {
                          ...item,
                          rebate_method: rebate.rebate_method === 'percent' ? 'fixed_amount' : 'percent'
                        } : item));
                      }
                    }} value={helpers.formatNumberWithCommas(rebate.rebate_value.toString())} onChange={value => product_variant_rebate.onChange(product_variant_rebate.value.map((item, i) => i === index ? {
                      ...item,
                      rebate_value: helpers.parseNumeric(value)
                    } : item))} suffix={rebate.rebate_method === 'percent' ? '%' : 'fixed'} connectedLeft={<Button onClick={() => deleteRuleToProductRebateCallback(index)} variant="plain" icon={DeleteIcon}></Button>} />

                          <Select label={'Cho nhóm người dùng'} options={allRoles} onChange={value => product_variant_rebate.onChange(product_variant_rebate.value.map((item, i) => i === index ? {
                      ...item,
                      user_role: String(value)
                    } : item))} value={rebate.user_role.toString()} />
                        </InlineGrid>;
                })}
                    <Button icon={PlusIcon} onClick={() => addNewRuleToProductRebateCallback()} variant="primary">
                      Thêm quy tắc
                    </Button>
                    <Text as="p" tone="subdued">
                      Chiết khấu là số tiền cứng sau khi bán một sản phẩm. Số tiền này được hoàn lại vào tài khoản của người mua sau khi đơn hàng được
                      đối soát.
                    </Text>
                  </BlockStack>}

                <Divider />
                <ChoiceList title="Chiết khấu phân tầng (tiered_rebate)" choices={[{
                label: 'Tuân theo chính sách chung',
                value: '-1'
              }, {
                label: 'Không có chiết khấu',
                value: '0'
              }, {
                label: 'Tùy chỉnh',
                value: '1'
              }]} selected={[useFields.variant_has_tiered_rebate.value]} onChange={a => useFields.variant_has_tiered_rebate.onChange(a[0])} />

                {useFields.variant_has_tiered_rebate.value === '1' && <BlockStack gap="400">
                    {product_variant_tiered_rebate.value.map((tiered_rebate, index) => {
                  return <TextField autoComplete="off" label={`Chiết khấu cho ${__(`user_role_` + tiered_rebate.user_role)}`} labelAction={{
                    content: __('commission_method_label_' + tiered_rebate.rebate_method),
                    onAction: () => {
                      product_variant_tiered_rebate.onChange(product_variant_tiered_rebate.value.map((item, i) => i === index ? {
                        ...item,
                        rebate_method: tiered_rebate.rebate_method === 'percent' ? 'fixed_amount' : 'percent'
                      } : item));
                    }
                  }} value={helpers.formatNumberWithCommas(tiered_rebate.rebate_value.toString())} onChange={value => product_variant_tiered_rebate.onChange(product_variant_tiered_rebate.value.map((item, i) => i === index ? {
                    ...item,
                    rebate_value: helpers.parseNumeric(value)
                  } : item))} suffix={tiered_rebate.rebate_method === 'percent' ? '%' : 'fixed'} />;
                })}
                    <Text as="p" tone="subdued">
                      Chiết khấu phân tầng là CHIẾT KHẤU và chia theo tầng, ở tầng thấp hơn khi mua hàng, các tầng trên có liên quan sẽ nhận được giá
                      trị tương ứng.
                    </Text>
                    <Text as="p" tone="subdued">
                      Chiết khấu này cũng sẽ được chia ngay sau khi đơn hàng giao thành công và được đánh dấu là "Đã đối soát".
                    </Text>
                  </BlockStack>}
              </BlockStack>
            </Capacity>
            <Divider />

            <BlockStack gap="400">
              <TextField label="SKU (Đơn vị lưu kho)" autoComplete="off" {...fields.variant_sku} />
              <TextField label="Mã vạch (ISBN, UPC, GTIN, v.v.)" autoComplete="off" {...fields.variant_barcode} />
              <TextField label="Mô tả ngắn" autoComplete="off" multiline={2} maxLength={250} showCharacterCount {...fields.variant_excerpt} />
            </BlockStack>

            <br />
            <Divider />
            <br />
            <Checkbox label={'Cho phép bán biến thể này'} checked={fields.variant_status.value === 1} onChange={val => fields.variant_status.onChange(Number(val))} helpText={'Các đơn hàng đã lên sẽ không bị ảnh hưởng bởi việc tắt này.'} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}